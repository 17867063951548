import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useNavigate, NavLink } from "react-router-dom";
import io from "socket.io-client";

import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import toast from "react-hot-toast";

import moment from "moment";
import { TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const ActivityDataGraphByTime = () => {
  const navigate = useNavigate();
  const [fetchRawData, setFetchRawData] = useState({
    startDateTime: null,
    endDateTime: null,
    date: "",
  });
  let name, value;
  const handleFormData = (e, fieldName = null) => {
    // Handling TimePicker or other components that pass values directly
    if (fieldName) {
      name = fieldName;
      value = e; // Include milliseconds
    } else {
      // Handling standard form inputs like <input>, <select>
      name = e.target.name;
      value = e.target.value;
    }
    setFetchRawData({ ...fetchRawData, [name]: value });
  };
  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [deviceSno, setDeviceSno] = useState([]);
  const [deviceSnoVal, setDeviceSnoVal] = useState("");
  const [storeActivity, setStoreActivity] = useState("");
  const [storeLabel, setStoreLabel] = useState("");
  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const getDataByTime = async (event) => {
    event.preventDefault();
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-activity-data-by-time/${id}`;
    // // Check if cached data exists
    // const startDateTime = event.target.startDateTime.value;
    // const endDateTime = event.target.endDateTime.value;
    // const date = event.target.date.value;
    let { startDateTime, endDateTime, date } = fetchRawData;
    if ((startDateTime && !endDateTime) || (!startDateTime && endDateTime)) {
      toast("Please select both start and end times or leave both empty.");
      setData([]);
      return;
    }
    startDateTime = startDateTime
      ? moment(startDateTime).format("HH:mm:ss.SSS")
      : "";
    endDateTime = endDateTime ? moment(endDateTime).format("HH:mm:ss.SSS") : "";
    const band_id = deviceSnoVal;
    const activity = storeActivity;
    const label = storeLabel;
    toast.loading("Loading... Please wait!");
    const res = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        band_id: band_id,
        date: date,
        activity: activity,
        label: label,
      }),
    });
    const result = await res.json();
    toast.dismiss();
    if (res.ok) {
      toast.success(result.message);
      setData(result.data);
    } else {
      toast(result.message);
      setData([]);
    }
  };
  const initialDomain = { time: [data[0]?.time, data[data?.length - 1]?.time] };
  const [zoomDomain, setZoomDomain] = useState(initialDomain);
  const handleZoom = (domain) => {
    setZoomDomain(domain);
  };
  // useEffect(() => {
  //   getDataByTime();
  // }, []);

  // const handleButtonClick = () => {
  //   getDataByTime();
  // };

  const getDeviveSno = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-device-sno/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    if (res.ok) {
      setDeviceSno(result.data);
    } else {
      // throw new Error("Failed to fetch data");
    }
  };

  useEffect(() => {
    getDeviveSno();
  }, []);

  const [storeAccOrGyro, setStoreAccOrGyro] = useState("A");
  const [tooltipContent, setTooltipContent] = useState({});

  useEffect(() => {
    // Update the tooltip content based on storeAccOrGyro
    if (storeAccOrGyro === "A") {
      setTooltipContent({
        key1: "Time",
        key2: "Ax",
        key3: "Ay",
        key4: "Az",
        key5: "Activity",
        key6: "Intensity",
      });
    } else if (storeAccOrGyro === "G") {
      setTooltipContent({
        key1: "Time",
        key2: "Gx",
        key3: "Gy",
        key4: "Gz",
        key5: "Activity",
        key6: "Intensity",
      });
    } else if (storeAccOrGyro === "ARes") {
      setTooltipContent({
        key1: "Time",
        key2: "ARes",
        key3: "Activity",
        key4: "Intensity",
      });
    } else {
      setTooltipContent({
        key1: "Time",
        key2: "tiltX",
        key3: "TiltY",
        key4: "Activity",
        key5: "Intensity",
      });
    }
  }, [storeAccOrGyro]);
  const getLineStrokeColor = (lineKey) => {
    // Function to return the stroke color for a given line key
    const colors = {
      Ax: "#8884d8",
      Ay: "#82ca9d",
      Az: "#ffc658",
    };
    return colors[lineKey]; // You can replace getRandomColor with your color logic
  };
  return (
    <div className="content-wrapper">
      <div className="row mt-3">
        <div
          className="col-lg-9 col-md-8 grid-margin stretch-card graphCard"
          style={{ height: "820px" }}
        >
          <div className="card">
            <div
              className="d-flex manageLinkTable justify-content-between align-items-center mb-3"
              style={{ background: "#e7e7e7" }}
            >
              <h4 className="table_title card-title mb-1">
                Activity Data Graph{" "}
                {storeAccOrGyro === "A"
                  ? "Accelerometer"
                  : storeAccOrGyro === "G"
                  ? "Gyroscope"
                  : storeAccOrGyro === "ARes"
                  ? "ARes"
                  : "Tilt"}{" "}
                <span>
                  <button
                    className="btn btn-sm bg-white btn-icon-text border"
                    // onClick={handleButtonClick}
                  >
                    <i
                      className="typcn typcn-refresh"
                      style={{ height: "4px", width: "4px" }}
                    ></i>{" "}
                  </button>
                </span>
              </h4>
              <div className="manageTablesOptions">
                {/* <div>
                    {rmvBtn && (
                      <button
                        onClick={removeSelected}
                        className="btn removeAllBtn btn-sm btn-outline-danger btn-icon-text"
                      >
                        Remove Selected
                        <i
                          className="fa fa-trash btn-icon-append"
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div> */}
                <select
                  className="dropdown setpageSelection show form-control"
                  name="pagination"
                  style={{
                    padding: ".5rem .75rem",
                    borderRadius: "4px",
                  }}
                  onChange={(event) => setStoreAccOrGyro(event.target.value)}
                >
                  <option value="A">Accelerometer</option>
                  <option value="G">Gyroscope</option>
                  <option value="tilt">Tilt</option>
                  <option value="ARes">ARes</option>
                </select>

                <div style={{ margin: "auto 8px" }}></div>
                {/* <div style={{ margin: "auto 8px" }}>
                  <button
                    type="button"
                    className="btn btn-sm bg-white border"
                    onClick={() => {
                      navigate("/raw-data");
                    }}
                  >
                    <i
                      className="fa fa-th"
                      style={{
                        height: "8px",
                        width: "8px",
                        paddingRight: "16px",
                      }}
                    ></i>
                  </button>
                </div> */}
                <div style={{ margin: "auto 8px" }}>
                  <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item nav-profile dropdown">
                      <a
                        className="nav-link dropdown-toggle pl-0 pr-0"
                        href="#"
                        data-toggle="dropdown"
                        id="profileDropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-sm bg-white border"
                        >
                          <i
                            className="fa fa-th"
                            aria-hidden="true"
                            style={{
                              height: "8px",
                              width: "8px",
                              paddingRight: "16px",
                            }}
                          ></i>
                        </button>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right navbar-dropdown"
                        aria-labelledby="profileDropdown"
                      >
                        <NavLink to="/raw-data" className="dropdown-item">
                          <i
                            className="fa fa-database text-primary"
                            aria-hidden="true"
                          ></i>{" "}
                          Raw data
                        </NavLink>
                        <NavLink
                          to="/raw-data-labeled"
                          className="dropdown-item"
                        >
                          <i
                            className="fa-solid fa-tag text-primary"
                            aria-hidden="true"
                          ></i>{" "}
                          Labeled Data
                        </NavLink>
                      </div>
                    </li>
                  </ul>
                </div>
                <ul className="navbar-nav navbar-nav-right">
                  <li className="nav-item nav-profile dropdown">
                    <a
                      className="nav-link dropdown-toggle pl-0 pr-0"
                      href="#"
                      data-toggle="dropdown"
                      id="profileDropdown"
                    >
                      <button
                        type="button"
                        className="btn btn-sm bg-white"
                        style={{ border: "solid #2b80ff 1px" }}
                      >
                        <i
                          className="fa fa-line-chart"
                          aria-hidden="true"
                          style={{
                            height: "8px",
                            width: "8px",
                            paddingRight: "16px",
                            color: "#2b80ff",
                          }}
                        ></i>
                      </button>
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right navbar-dropdown"
                      aria-labelledby="profileDropdown"
                    >
                      {/* <NavLink to="/raw-data-graph" className="dropdown-item">
                        <i
                          className="fa fa-clock text-dark"
                          aria-hidden="true"
                        ></i>{" "}
                        Real Time
                      </NavLink> */}
                      <NavLink
                        to="/raw-data-graph-by-time"
                        className="dropdown-item"
                      >
                        <i
                          className="fa-solid fa-clock text-dark"
                          aria-hidden="true"
                        ></i>{" "}
                        Time Specific Raw
                      </NavLink>
                      <NavLink
                        to="/activity-data-graph-by-time"
                        className="dropdown-item"
                      >
                        <i
                          className="fa-solid fa-clock-rotate-left text-dark"
                          aria-hidden="true"
                        ></i>{" "}
                        Time Specific Labeled
                      </NavLink>
                    </div>
                  </li>
                </ul>
                {/* <div style={{ margin: "auto 8px" }}>
                    <input
                      type="date"
                      name="date"
                      id="date"
                      className="form-control"
                      style={{ height: "38px" }}
                    />
                  </div> */}

                {/* <div style={{ margin: "auto 8px" }}>
                    <button
                      type="button"
                      className="btn btn-sm bg-white border"
                      onClick={() => {
                        navigate("/raw-data");
                      }}
                    >
                      <i
                        className="fa fa-th"
                        style={{
                          height: "8px",
                          width: "8px",
                          paddingRight: "16px",
                        }}
                      ></i>
                    </button>
                  </div> */}
                {/* <div style={{ margin: "auto 8px" }}>
                    <button
                      type="button"
                      className="btn btn-sm bg-white"
                      style={{ border: "solid #2b80ff 1px" }}
                      onClick={() => {
                        navigate("#");
                      }}
                    >
                      <i
                        className="fa fa-line-chart"
                        style={{
                          height: "8px",
                          width: "8px",
                          paddingRight: "16px",
                          color: "#2b80ff",
                        }}
                      ></i>
                    </button>
                  </div> */}
              </div>
            </div>
            <div className="card-body" style={{ background: "#ffffff" }}>
              {/* <div>
                <ResponsiveContainer width="100%" height={600}>
                  <BarChart
                    data={data}
                    // style={{background:"#ffffff"}}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="time"
                      tick={{ fontSize: 10, angle: -90, dy: 100 }} // Customize the tick properties
                      interval={6} // Show all ticks, or adjust this value to set the interval between ticks
                    />
                    <YAxis
                    // domain={[-1, 1]}
                    // scale="linear"
                    // tickFormatter={(value) =>
                    //   value === 1 || value === -1 ? value : value.toFixed(3)
                    // }
                    />
                    <Tooltip />

                    <Legend
                      layout="horizontal"
                      verticalAlign="top"
                      // height={36}
                    />
                    <Brush
                      dataKey="time"
                      height={15}
                      stroke="#8884d8"
                      travellerWidth={6}
                      onChange={handleZoom}
                      style={{ overflow: "visible" }}
                    />
                    {storeAccOrGyro === "tilt" ? (
                      <>
                        <Bar
                          type="monotone"
                          dataKey={`${storeAccOrGyro}X`}
                          fill="#8884d8"
                          dot={false}
                        />
                        <Bar
                          type="monotone"
                          dataKey={`${storeAccOrGyro}Y`}
                          fill="#82ca9d"
                          dot={false}
                        />
                        {console.log(storeAccOrGyro)}
                      </>
                    ) : storeAccOrGyro === "ARes" ? (
                      <Bar
                        type="monotone"
                        dataKey={`${storeAccOrGyro}`}
                        fill="#8884d8"
                        dot={false}
                      />
                    ) : (
                      <>
                        <Bar
                          type="monotone"
                          dataKey={`${storeAccOrGyro}x`}
                          fill="#8884d8"
                          dot={false}
                        />
                        <Bar
                          type="monotone"
                          dataKey={`${storeAccOrGyro}y`}
                          fill="#82ca9d"
                          dot={false}
                        />
                        <Bar
                          type="monotone"
                          dataKey={`${storeAccOrGyro}z`}
                          fill="#ffc658"
                          dot={false}
                        />
                      </>
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </div> */}
              <div>
                <ResponsiveContainer width="100%" height={600}>
                  <LineChart
                    data={data}
                    // style={{background:"#ffffff"}}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="time"
                      tick={{ fontSize: 10, angle: -90, dy: 100 }} // Customize the tick properties
                      interval={6} // Show all ticks, or adjust this value to set the interval between ticks
                    />
                    <YAxis
                    // domain={[-1, 1]}
                    // scale="linear"
                    // tickFormatter={(value) =>
                    //   value === 1 || value === -1 ? value : value.toFixed(3)
                    // }
                    />
                    <Tooltip
                      content={({ payload, label, active }) => {
                        if (active && payload && payload.length) {
                          const tooltipData = payload[0].payload;
                          let content;

                          if (storeAccOrGyro === "A") {
                            content = (
                              <div className="custom-tooltip">
                                <p>{`${tooltipData.time}`}</p>
                                <ul>
                                  <li
                                    style={{
                                      color: "#8884d8",
                                    }}
                                  >{`${tooltipContent.key2} : ${tooltipData.Ax}`}</li>
                                  <li
                                    style={{
                                      color: "#82ca9d",
                                    }}
                                  >{`${tooltipContent.key3} : ${tooltipData.Ay}`}</li>
                                  <li
                                    style={{
                                      color: "#ffc658",
                                    }}
                                  >{`${tooltipContent.key4} : ${tooltipData.Az}`}</li>
                                  <li>{`${tooltipContent.key5} : ${tooltipData.activity}`}</li>
                                  <li>{`${tooltipContent.key6} : ${tooltipData.label}`}</li>
                                </ul>
                              </div>
                            );
                          } else if (storeAccOrGyro === "G") {
                            content = (
                              <div className="custom-tooltip">
                                <p>{`${tooltipData.time}`}</p>
                                <ul>
                                  <li
                                    style={{
                                      color: "#8884d8",
                                    }}
                                  >{`${tooltipContent.key2} : ${tooltipData.Gx}`}</li>
                                  <li
                                    style={{
                                      color: "#82ca9d",
                                    }}
                                  >{`${tooltipContent.key3} : ${tooltipData.Gy}`}</li>
                                  <li
                                    style={{
                                      color: "#ffc658",
                                    }}
                                  >{`${tooltipContent.key4} : ${tooltipData.Gz}`}</li>
                                  <li>{`${tooltipContent.key5} : ${tooltipData.activity}`}</li>
                                  <li>{`${tooltipContent.key6} : ${tooltipData.label}`}</li>
                                </ul>
                              </div>
                            );
                          } else if (storeAccOrGyro === "ARes") {
                            content = (
                              <div className="custom-tooltip">
                                <p>{`${tooltipData.time}`}</p>
                                <ul>
                                  <li
                                    style={{
                                      color: "#8884d8",
                                    }}
                                  >{`${tooltipContent.key2} : ${tooltipData.ARes}`}</li>
                                  <li>{`${tooltipContent.key3} : ${tooltipData.activity}`}</li>
                                  <li>{`${tooltipContent.key4} : ${tooltipData.label}`}</li>
                                </ul>
                              </div>
                            );
                          } else if (storeAccOrGyro === "tilt") {
                            content = (
                              <div className="custom-tooltip">
                                <p>{`${tooltipData.time}`}</p>
                                <ul>
                                  <li
                                    style={{
                                      color: "#8884d8",
                                    }}
                                  >{`${tooltipContent.key2} : ${tooltipData.tiltX}`}</li>
                                  <li
                                    style={{
                                      color: "#82ca9d",
                                    }}
                                  >{`${tooltipContent.key3} : ${tooltipData.tiltY}`}</li>
                                  <li>{`${tooltipContent.key4} : ${tooltipData.activity}`}</li>
                                  <li>{`${tooltipContent.key5} : ${tooltipData.label}`}</li>
                                </ul>
                              </div>
                            );
                          }

                          return content;
                        }

                        return null;
                      }}
                    />

                    <Legend
                      layout="horizontal"
                      verticalAlign="top"
                      // height={36}
                    />
                    <Brush
                      dataKey="time"
                      height={15}
                      stroke="#8884d8"
                      travellerWidth={6}
                      onChange={handleZoom}
                      style={{ overflow: "visible" }}
                    />
                    {storeAccOrGyro === "tilt" ? (
                      <>
                        <Line
                          type="monotone"
                          dataKey={`${storeAccOrGyro}X`}
                          stroke="#8884d8"
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey={`${storeAccOrGyro}Y`}
                          stroke="#82ca9d"
                          dot={false}
                        />
                        {console.log(storeAccOrGyro)}
                      </>
                    ) : storeAccOrGyro === "ARes" ? (
                      <Line
                        type="monotone"
                        dataKey={`${storeAccOrGyro}`}
                        stroke="#8884d8"
                        dot={false}
                      />
                    ) : (
                      <>
                        <Line
                          type="monotone"
                          dataKey={`${storeAccOrGyro}x`}
                          stroke="#8884d8"
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey={`${storeAccOrGyro}y`}
                          stroke="#82ca9d"
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey={`${storeAccOrGyro}z`}
                          stroke="#ffc658"
                          dot={false}
                        />
                      </>
                    )}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 grid-margin stretch-card">
          <div className="card">
            <div
              className="card-body"
              style={{ background: "#ffffff", padding: "0.175rem 1.875rem" }}
            >
              <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                <h5 className="mb-1 mt-4">Plot graph within time duration</h5>
              </div>
              <form
                className="forms-sample"
                autoComplete="off"
                onSubmit={getDataByTime}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="datePicker">Serial Number/Band Id</label>
                      <select
                        className="dropdown show form-control"
                        name="pagination"
                        style={{
                          padding: ".5rem .75rem",
                          borderRadius: "4px",
                        }}
                        onChange={(e) => setDeviceSnoVal(e.target.value)}
                        value={deviceSnoVal}
                      >
                        <option value="">Select</option>
                        {deviceSno &&
                          deviceSno?.length > 0 &&
                          deviceSno.map((option, index) => {
                            return (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="activity">Activity</label>
                      <select
                        className="dropdown setpageSelection show form-control"
                        name="activity"
                        onChange={(event) =>
                          setStoreActivity(event.target.value)
                        }
                        value={storeActivity}
                        style={{
                          padding: ".5rem .75rem",
                          borderRadius: "4px",
                        }}
                      >
                        <option value="">Select Activity</option>
                        <option value="Walking">Walking</option>
                        <option value="Running">Running</option>
                        <option value="Playing">Playing</option>
                        <option value="Jumping">Jumping</option>
                        <option value="Resting">Resting</option>
                        {/* <option value="Training">Training</option> */}
                        <option value="Standing">Standing</option>
                        <option value="Sitting">Sitting</option>
                        <option value="Sleeping">Sleeping</option>
                        <option value="Drinking">Drinking</option>
                        <option value="Eating">Eating</option>
                        <option value="Scratching">Scratching</option>
                        <option value="Licking">Licking</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="label">Intensity</label>
                      <select
                        className="dropdown setpageSelection show form-control"
                        name="label"
                        onChange={(event) => setStoreLabel(event.target.value)}
                        value={storeLabel}
                        style={{
                          padding: ".5rem .75rem",
                          borderRadius: "4px",
                        }}
                      >
                        <option value={""}>Select Intensity</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                        <option value="Sedentary">Sedentary</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="datePicker">Date</label>
                      <input
                        type="date"
                        id="datePicker"
                        name="date"
                        max={new Date().toISOString().split("T")[0]}
                        value={fetchRawData.date}
                        onChange={handleFormData}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="startTimePicker">From</label>
                      <input
                        type="time"
                        id="startTimePicker"
                        name="startDateTime"
                        className="form-control"
                        step="0.001"
                      ></input>
                    </div>
                  </div> */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="startTimePicker">From</label>
                        <TimePicker
                          value={fetchRawData.startDateTime}
                          onChange={(newValue) =>
                            handleFormData(newValue, "startDateTime")
                          }
                          className="form-control"
                          renderInput={(params) => <TextField {...params} />}
                          format="HH:mm:ss"
                          views={["hours", "minutes", "seconds"]}
                          timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                          maxTime={moment().endOf("day")}
                          ampm={false}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                  {/* <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="endTimePicker">To</label>
                      <input
                        type="time"
                        id="endTimePicker"
                        name="endDateTime"
                        className="form-control"
                        step="0.001"
                      ></input>
                    </div>
                  </div> */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="endDateTime">To</label>
                        <TimePicker
                          value={fetchRawData.endDateTime}
                          onChange={(newValue) =>
                            handleFormData(newValue, "endDateTime")
                          }
                          className="form-control"
                          renderInput={(params) => <TextField {...params} />}
                          format="HH:mm:ss"
                          views={["hours", "minutes", "seconds"]}
                          timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                          maxTime={moment().endOf("day")}
                          ampm={false}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary mr-2 px-5 py-3"
                      id="loginBtn"
                    >
                      Plot Graph
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityDataGraphByTime;
