import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import "./../../assets/css/login.css";
import toast from "react-hot-toast";
function Login() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();

  const [showPassowrd, setShowPassowrd] = useState(false);

  // Check user logged in or not
  //if use logged in - Redirect on Dashboard
  useEffect(() => {
    const loginCheck = String(localStorage.getItem("logged_in"));
    if (loginCheck == "true") {
      navigate("/dashboard");
    }
  });
  const [for_email, setForEmail] = useState(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gm
  );

  //==================| On submit function |=======================
  const handelsubmit = async (event) => {
    event.preventDefault();
    // console.log("Clicked");
    // localStorage.setItem("logged_in", "true");
    // navigate("/dashboard");
    document.getElementById(
      "loginBtn"
    ).innerHTML = `<span class="load loading"></span>`;

    const email = event.target.email.value;
    const password = event.target.password.value;
    // console.log(email, password);
    if (!email.match(for_email)) {
      console.log("enter valid email");
      document.getElementById("loginBtn").innerHTML = `Sign in`;
      toast.error(`Enter valid email!`);
      return false;
    }
    // console.log("email: ", email, ", password: ", password);
    // ======================|LOGIN API|==========================

    var BASE_URL = SERVER_URL + "/api/admin/login";

    let resData = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
    const res = await resData.json();
    // console.log(resData);
    // console.log(res);
    if (resData.ok) {
      localStorage.setItem("logged_in", true);
      document.getElementById("loginBtn").innerHTML = `Sign In`;

      localStorage.setItem("logged_res", JSON.stringify(res.token));
      localStorage.setItem("adminId", JSON.stringify(res.admin_id));

      // to Redirect on dashobard
      setTimeout(() => {
        localStorage.setItem("logged_in", true);
        navigate("/dashboard");
        document.getElementById("loginBtn").innerHTML = `Sign In`;
      }, 2000);
      toast.success("Logged in successfully!");
    } else {
      document.getElementById("loginBtn").innerHTML = `Sign In`;
      toast.error(`${res.message}`);
    }
  };

  // ================| Show/Hide Password |========================
  function hideShowPassword() {
    // let eye = document.getElementById("eyeIcon");
    let password = document.getElementById("password");
    if (showPassowrd) {
      // eye.innerHTML = `<i className="fa fa-eye-slash" aria-hidden="true"></i>`;
      password.type = "password";
      setShowPassowrd(false);
    } else {
      // eye.innerHTML = `<i className="fa fa-eye" aria-hidden="true"></i>`;
      password.type = "text";
      setShowPassowrd(true);
    }
  }
  return (
    <section className="login-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-10">
            <div className="wrap d-md-flex">
              <div className="img"></div>
              <div className="login-wrap p-4 p-md-5">
                <div className="w-100">
                  <h3 className="mb-4 diamondsOnCallTitle">PAWZMO</h3>

                  <h5 className="mb-4 form_title">Sign In</h5>
                </div>
                <form
                  action="#"
                  onSubmit={handelsubmit}
                  className="signin-form"
                >
                  <div className="form-group mb-3">
                    <label className="label" htmlFor="email">
                      Email
                    </label>
                    <input
                      id="email"
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group mb-3 passwordInputField">
                    <label className="label" htmlFor="password">
                      Password
                    </label>
                    <input
                      id="password"
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      autoComplete="off"
                      required
                    />
                    <span onClick={hideShowPassword} id="eyeIcon">
                      <i
                        className={`fa  ${
                          showPassowrd ? "fa-eye" : "fa-eye-slash"
                        }`}
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                  <div className="form-group">
                    <button
                      id="loginBtn"
                      type="submit"
                      className="form-control btn btn-primary rounded signInBtn px-3"
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="form-group d-md-flex forgot_password">
                    <Link to="/forgot-password">
                      <i className="fa fa-lock" aria-hidden="true"></i> Forgot
                      Password
                    </Link>
                  </div>
                </form>
                {/* <p className="text-center signInLink">
                  Not a member?{" "}
                  <a className="signUpLink" href="#signup">
                    Sign Up
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
