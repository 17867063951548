import React, {
  useState,
  // useRef,
  useEffect,
  // useMemo,
  // useCallback,
} from "react";
import { useNavigate, NavLink } from "react-router-dom";
import io from "socket.io-client";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const RawDataGraph = () => {
  const navigate = useNavigate();

  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [websocketData, setWebsocketData] = useState([]);
  const [serialNumber, setSerialNumber] = useState("4C:75:25:A8:46:90");
  const [storeType, setStoreType] = useState("battery");
  const WEBSOCKET_SERVER_URL = process.env.REACT_APP_WEBSOCKET_SERVER_URL;
  useEffect(() => {
    const socket = io(WEBSOCKET_SERVER_URL, {
      transports: ["websocket"],
      query: { serial: serialNumber, type: storeType },
    });

    // socket.on("data", (newData) => {
    //   setWebsocketData(newData);
    //   // setWebsocketData((prevData) => [...prevData, ...newData]);
    // });
    socket.on("statusData", (newData) => {
      // setWebsocketData(newData);
      console.log(newData);
      // setWebsocketData((prevData) => [...prevData, ...newData]);
    });
    socket.on("healthData", (newData) => {
      // setWebsocketData(newData);
      console.log(newData);
      // setWebsocketData((prevData) => [...prevData, ...newData]);
    });
    return () => {
      socket.disconnect();
    };
  }, [serialNumber]);

  // console.log(websocketData);
  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const getData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-device-data`;
    // // Check if cached data exists
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    setData(result.data);
  };
  useEffect(() => {
    getData();
  }, []);

  const handleButtonClick = () => {
    getData();
  };
  const [storeAccOrGyro, setStoreAccOrGyro] = useState("A");

  return (
    <div className="content-wrapper">
      <div className="row mt-3">
        <div
          className="col-lg-10 col-md-8 grid-margin stretch-card graphCard"
          style={{ height: "800px" }}
        >
          <div className="card">
            <div
              className="d-flex manageLinkTable justify-content-between align-items-center mb-3"
              style={{ background: "#e7e7e7" }}
            >
              <h4 className="table_title card-title mb-1">
                Raw Data Real Time Graph{" "}
                <span>
                  <button
                    className="btn btn-sm bg-white btn-icon-text border"
                    onClick={handleButtonClick}
                  >
                    <i
                      className="typcn typcn-refresh"
                      style={{ height: "4px", width: "4px" }}
                    ></i>{" "}
                  </button>
                </span>
              </h4>
              <div className="manageTablesOptions">
                {/* <div>
                    {rmvBtn && (
                      <button
                        onClick={removeSelected}
                        className="btn removeAllBtn btn-sm btn-outline-danger btn-icon-text"
                      >
                        Remove Selected
                        <i
                          className="fa fa-trash btn-icon-append"
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div> */}

                <div style={{ margin: "auto 8px" }}>
                  <select
                    className="dropdown setpageSelection show form-control"
                    name="pagination"
                    style={{
                      padding: ".5rem .75rem",
                      borderRadius: "4px",
                    }}
                    onChange={(event) => setStoreAccOrGyro(event.target.value)}
                  >
                    <option value="A">Accelerometer</option>
                    <option value="G">Gyroscope</option>
                    <option value="tilt">Tilt</option>
                    <option value="ARes">ARes</option>
                  </select>
                </div>
                {/* <div style={{ margin: "auto 8px" }}>
                  <select
                    className="dropdown setpageSelection show form-control"
                    name="pagination"
                    style={{
                      padding: ".5rem .75rem",
                      borderRadius: "4px",
                    }}
                  >
                    <option value={"device1A"}>Device 1A</option>
                    <option value={"device1B"}>Device 1B</option>
                    <option value={"device1C"}>Device 1C</option>
                    <option value={"device1D"}>Device 1D</option>
                  </select>
                </div> */}

                {/* <div style={{ margin: "auto 8px" }}>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    className="form-control"
                    style={{ height: "38px" }}
                  />
                </div> */}
                <div style={{ margin: "auto 8px" }}>
                  <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item nav-profile dropdown">
                      <a
                        className="nav-link dropdown-toggle pl-0 pr-0"
                        href="#"
                        data-toggle="dropdown"
                        id="profileDropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-sm bg-white border"
                        >
                          <i
                            className="fa fa-th"
                            aria-hidden="true"
                            style={{
                              height: "8px",
                              width: "8px",
                              paddingRight: "16px",
                            }}
                          ></i>
                        </button>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right navbar-dropdown"
                        aria-labelledby="profileDropdown"
                      >
                        <NavLink to="/raw-data" className="dropdown-item">
                          <i
                            className="fa fa-database text-primary"
                            aria-hidden="true"
                          ></i>{" "}
                          Raw data
                        </NavLink>
                        <NavLink
                          to="/raw-data-labeled"
                          className="dropdown-item"
                        >
                          <i
                            className="fa-solid fa-tag text-primary"
                            aria-hidden="true"
                          ></i>{" "}
                          Labeled Data
                        </NavLink>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div style={{ margin: "auto 8px" }}>
                    <button
                      type="button"
                      className="btn btn-sm bg-white border"
                      onClick={() => {
                        navigate("/raw-data");
                      }}
                    >
                      <i
                        className="fa fa-th"
                        style={{
                          height: "8px",
                          width: "8px",
                          paddingRight: "16px",
                        }}
                      ></i>
                    </button>
                  </div> */}
                {/* <div style={{ margin: "auto 8px" }}>
                    <button
                      type="button"
                      className="btn btn-sm bg-white"
                      style={{ border: "solid #2b80ff 1px" }}
                      onClick={() => {
                        navigate("#");
                      }}
                    >
                      <i
                        className="fa fa-line-chart"
                        style={{
                          height: "8px",
                          width: "8px",
                          paddingRight: "16px",
                          color: "#2b80ff",
                        }}
                      ></i>
                    </button>
                  </div> */}
                <ul className="navbar-nav navbar-nav-right">
                  <li className="nav-item nav-profile dropdown">
                    <a
                      className="nav-link dropdown-toggle pl-0 pr-0"
                      href="#"
                      data-toggle="dropdown"
                      id="profileDropdown"
                    >
                      <button
                        type="button"
                        className="btn btn-sm bg-white"
                        style={{ border: "solid #2b80ff 1px" }}
                      >
                        <i
                          className="fa fa-line-chart"
                          aria-hidden="true"
                          style={{
                            height: "8px",
                            width: "8px",
                            paddingRight: "16px",
                            color: "#2b80ff",
                          }}
                        ></i>
                      </button>
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right navbar-dropdown"
                      aria-labelledby="profileDropdown"
                    >
                      <NavLink to="/raw-data-graph" className="dropdown-item">
                        <i
                          className="fa fa-clock text-dark"
                          aria-hidden="true"
                        ></i>{" "}
                        Real Time
                      </NavLink>
                      <NavLink
                        to="/raw-data-graph-by-time"
                        className="dropdown-item"
                      >
                        <i
                          className="fa-solid fa-clock-rotate-left text-dark"
                          aria-hidden="true"
                        ></i>{" "}
                        Time Specific
                      </NavLink>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body" style={{ background: "#ffffff" }}>
              <div>
                <ResponsiveContainer width="100%" height={600}>
                  <LineChart data={websocketData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="time"
                      tick={{ fontSize: 10, angle: -60, dy: 50 }} // Customize the tick properties
                      interval={0} // Show all ticks, or adjust this value to set the interval between ticks
                    />
                    <YAxis
                    // domain={[-1, 1]}
                    // scale="linear"
                    // tickFormatter={(value) =>
                    //   value === 1 || value === -1 ? value : value.toFixed(3)
                    // }
                    />
                    <Tooltip />
                    <Legend layout="horizontal" verticalAlign="top" />
                    {storeAccOrGyro === "tilt" ? (
                      <>
                        <Line
                          type="monotone"
                          dataKey={`${storeAccOrGyro}X`}
                          stroke="#8884d8"
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey={`${storeAccOrGyro}Y`}
                          stroke="#82ca9d"
                          dot={false}
                        />
                        {console.log(storeAccOrGyro)}
                      </>
                    ) : storeAccOrGyro === "ARes" ? (
                      <Line
                        type="monotone"
                        dataKey={`${storeAccOrGyro}`}
                        stroke="#8884d8"
                        dot={false}
                      />
                    ) : (
                      <>
                        <Line
                          type="monotone"
                          dataKey={`${storeAccOrGyro}x`}
                          stroke="#8884d8"
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey={`${storeAccOrGyro}y`}
                          stroke="#82ca9d"
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey={`${storeAccOrGyro}z`}
                          stroke="#ffc658"
                          dot={false}
                        />
                      </>
                    )}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 grid-margin stretch-card">
          <div className="card">
            <div
              className="card-body"
              style={{ background: "#ffffff", padding: "0.175rem 1.875rem" }}
            >
              <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                <h5 className="mb-1 mt-4">Apply Filters </h5>
              </div>
              <div style={{ margin: "12px auto" }}>
              <select
                  className="dropdown setpageSelection show form-control"
                  name="pagination"
                  style={{
                    padding: ".5rem .75rem",
                    borderRadius: "4px",
                  }}
                  onChange={(e) => setSerialNumber(e.target.value)}
                >
                  <option value={"4C:75:25:A8:46:90"}>4C:75:25:A8:46:90</option>
                  <option value={"4C:75:25:A8:2C:64"}>4C:75:25:A8:2C:64</option>
                </select>
              </div>
              <div className="text-center">
                <button type="button" className="btn btn-primary">
                  Apply
                </button>
              </div>
              <div className="text-center" style={{ margin: "12px auto" }}>
                <button type="button" className="btn btn-outline-primary">
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RawDataGraph;
