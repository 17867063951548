import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import toast from "react-hot-toast";

import moment from "moment";
import { TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const RawDataLabeled = () => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const navigate = useNavigate();
  // const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [paginationRow, setPaginationRow] = useState(10); // Set rowData to Array of Objects, one Object per Row
  const [size, setSize] = useState(0);
  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [deviceSno, setDeviceSno] = useState([]);
  const [deviceSnoVal, setDeviceSnoVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const startItem = (currentPage - 1) * paginationRow + 1;
  const endItem = Math?.min(startItem + data?.length - 1, totalItems);
  // const getData = async (page = 1) => {
  //   const id = localStorage.getItem("adminId")?.replace(/"/g, "");
  //   const bandId = ""; // Replace "your_band_id" with the actual band ID you want to query
  //   let BASE_URL =
  //     SERVER_URL +
  //     `/api/admin/get-device-data-pp/${id}?band_id=${bandId}&page=${page}&limit=${paginationRow}`;

  //   try {
  //     const res = await fetch(BASE_URL, {
  //       method: "GET",
  //       headers: {
  //         authorization: `Bearer ${JSON.parse(
  //           localStorage.getItem("logged_res")
  //         )}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if (!res.ok) {
  //       throw new Error("Failed to fetch data");
  //     }
  //     const result = await res.json();
  //     setData(result.data);
  //     setCurrentPage(result?.pagination.currentPage);
  //     setTotalPages(result?.pagination.totalPages);
  //     setTotalItems(result?.pagination.totalItems);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const [fetchRawData, setFetchRawData] = useState({
    startDateTime: null,
    endDateTime: null,
    date: "",
    phone: "",
    label: "",
    activity: "",
    dogId: "",
  });
  let name, value;
  const handleFormData = (e, fieldName = null) => {
    // Handling TimePicker or other components that pass values directly
    if (fieldName) {
      name = fieldName;
      value = e; // Include milliseconds
    } else {
      // Handling standard form inputs like <input>, <select>
      name = e.target.name;
      value = e.target.value;
    }
    setFetchRawData({ ...fetchRawData, [name]: value });
  };
  const getData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    const bandId = deviceSnoVal || ""; // Replace "your_band_id" with the actual band ID you want to query
    let { startDateTime, endDateTime, date, activity, label } = fetchRawData;
    if ((startDateTime && !endDateTime) || (!startDateTime && endDateTime)) {
      toast("Please select both start and end times or leave both empty.");
      if (gridRef.current) {
        gridRef.current.api.hideOverlay();
      }
      setData([]);
      setTotalCount(0);
      return;
    }
    toast.loading("Loading... Please wait!");
    startDateTime = startDateTime
      ? moment(startDateTime).format("HH:mm:ss.SSS")
      : "";
    endDateTime = endDateTime ? moment(endDateTime).format("HH:mm:ss.SSS") : "";
    let BASE_URL =
      SERVER_URL +
      `/api/admin/get-label-device-data-by-sno-date/${id}?band_id=${bandId}&date=${date}&startDateTime=${startDateTime}&endDateTime=${endDateTime}&activity=${activity}&label=${label}`;
    // SERVER_URL + `/api/admin/get-device-data-sno/${id}?band_id=${bandId}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    toast.dismiss();
    if (gridRef.current) {
      gridRef.current.api.hideOverlay();
    }
    if (res.ok) {
      if (deviceSnoVal) {
        toast.success(result.message);
      }
      setData(result.data);
      setTotalCount(result.totalCount);
    } else {
      setData([]);
      setTotalCount(0);
      toast(result.message);
    }
  };
  const onGridReady = useCallback((params) => {
    // params.api.showLoadingOverlay();
    if (gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  });

  let check = true;
  useEffect(() => {
    if (check) {
      getData();
    }
    check = false;
    // }, [deviceSnoVal]);
  }, []);

  const getDeviveSno = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-device-sno/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();
    if (res.ok) {
      setDeviceSno(result.data);
    } else {
      // throw new Error("Failed to fetch data");
    }
  };

  // const insertLabelActivity = async (event) => {
  //   const id = localStorage.getItem("adminId")?.replace(/"/g, "");
  //   const { label, activity, dogId } = fetchRawData;
  //   console.log(label, activity, dogId);
  //   let BASE_URL =
  //     SERVER_URL + `/api/admin/insert-label-activity-by-time/${id}`;
  //   const res = await fetch(BASE_URL, {
  //     method: "POST",
  //     headers: {
  //       authorization: `Bearer ${JSON.parse(
  //         localStorage.getItem("logged_res")
  //       )}`,
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       selectedData: storedSelected, // To validate token
  //       label: label,
  //       activity: activity,
  //       dogId: dogId,
  //     }),
  //   });
  //   const result = await res.json();
  //   if (res.ok) {
  //     toast.success(result.message);
  //     getData();
  //   } else {
  //     toast.error(result.message);
  //     getData();
  //   }
  // };

  useEffect(() => {
    getDeviveSno();
  }, []);

  // useEffect(() => {
  //   // Reset the current page to 1 whenever the pagination size changes
  //   setCurrentPage(1);
  //   // Fetch data with the updated paginationRow value
  //   getData(1);
  // }, [paginationRow]);

  const handlePageChange = (newPage) => {
    // setCurrentPage(newPage);
    // getData(newPage);
  };
  // ========================================[For Filter Error End]===================================================

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);

  // =========================================[For Diamond trn_log Data]============================================
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "S.No",
      width: 105,
      // For selection
      valueGetter: (params) => params.node.rowIndex + 1,
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      // showDisabledCheckboxes: true,
    },
    // { headerName: "Id", field: "id", width: 150 },
    {
      headerName: "Band Id/Serial Number",
      field: "SerialNumber",
      minWidth: 205,
    },
    { headerName: "Current Date", field: "CurrentDate", minWidth: 205 },
    { headerName: "Current Time", field: "CurrentTime", minWidth: 205 },
    { headerName: "Time", field: "time", minWidth: 205 },
    {
      headerName: "ARes",
      width: 150,
      field: "ARes",
    },
    {
      headerName: "Ax",
      width: 150,
      field: "Ax",
    },
    {
      headerName: "Ay",
      width: 150,
      field: "Ay",
    },
    {
      headerName: "Az",
      width: 150,
      field: "Az",
    },
    {
      headerName: "Gx",
      width: 150,
      field: "Gx",
    },
    {
      headerName: "Gy",
      width: 150,
      field: "Gy",
    },
    {
      headerName: "Gz",
      width: 150,
      field: "Gz",
    },
    {
      headerName: "Temperature",
      width: 150,
      field: "Temperature",
    },
    {
      headerName: "TiltX",
      width: 150,
      field: "tiltX",
    },
    {
      headerName: "TiltY",
      width: 150,
      field: "tiltY",
    },
    {
      headerName: "Intensity",
      field: "label",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Activity",
      field: "activity",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Dog Id",
      field: "dogId",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Dog Breed",
      field: "dogBreed",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Dog Weight",
      field: "dogWeight",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Dog Height",
      field: "dogHeight",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Coat type",
      field: "coatType",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Fur length",
      field: "furLength",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Neutered",
      field: "neutered",
      width: 150,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
  ]);

  // Col Defs For Sample Pending Data End===============================================
  // ==============================================[For selected Data]=========================================
  const [selectedData, setSelectedData] = useState([]);
  const [rmvBtn, setRmvBtn] = useState(false);
  //   console.log(selectedData);
  const storedSelected = selectedData.map((sel) => {
    // return sel.data.SerialNumber;
    return sel.data;
  });
  // console.log(storedSelected);

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 1) {
      setRmvBtn(true);
    }
    if (rowCount < 2) {
      setRmvBtn(false);
    }
    console.log(event.api.getSelectedNodes());
    setSelectedData(event.api.getSelectedNodes());
    // window.alert("selection changed, " + rowCount + " rows selected");
  }, []);
  // ==============================================[For selected data End]=========================================

  // ============================================[For Remove selected data Start]====================================
  const removeSelected = useCallback(() => {
    const selectedRowNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedRowNodes.map(function (rowNode) {
      return rowNode.data.id;
    });
    const confirmation = window.confirm(
      "Are you sure? You want to delete multiple data!"
    );
    if (confirmation) {
      const filteredData = data.filter(function (dataItem) {
        return selectedIds.indexOf(dataItem.id) < 0;
      });
      setData(filteredData);
      setRmvBtn(false);
    }
  }, [data]);
  // ============================================[For Remove selected data End]====================================

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // make every column sortable
    sortable: true,
    // make every column filter
    filter: true,
    // filterParams: {
    //   buttons: ["reset", "apply"],
    //   // debounceMs: 100,
    // },
    // enable floating filters by default
    // floatingFilter: true,
    // make columns resizable
    resizable: true,
  }));

  // Example load data from sever
  // useEffect(() => {
  //   fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
  //     .then((result) => result.json())

  //     .then((rowData) => setRowData(rowData));
  // }, []);

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleButtonClick = () => {
    gridRef.current.api.showLoadingOverlay();
    getData();
  };
  const handleResetClick = () => {
    setFetchRawData({
      startDateTime: null,
      endDateTime: null,
      date: "",
      phone: "",
      label: "",
      activity: "",
      dogId: "",
    });
    setDeviceSnoVal("");
    gridRef.current.api.showLoadingOverlay();
    getData();
  };
  return (
    <div className="content-wrapper">
      <div className="row mt-3">
        <div className="col-lg-9 col-md-8 grid-margin stretch-card">
          <div className="card">
            <div
              className="d-flex manageLinkTable justify-content-between align-items-center"
              style={{ background: "#e7e7e7" }}
            >
              <h4 className="table_title card-title mb-1">
                Raw Labeled Data{" "}
                <span>
                  <button
                    className="btn btn-sm bg-white btn-icon-text border"
                    onClick={handleButtonClick}
                  >
                    <i
                      className="typcn typcn-refresh"
                      style={{ height: "4px", width: "4px" }}
                    ></i>{" "}
                  </button>
                </span>
              </h4>
              <div className="manageTablesOptions">
                {/* <div>
                    {rmvBtn && (
                      <button
                        onClick={removeSelected}
                        className="btn removeAllBtn btn-sm btn-outline-danger btn-icon-text"
                      >
                        Remove Selected
                        <i
                          className="fa fa-trash btn-icon-append"
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div> */}
                {/* Working below commented */}
                {/* <div style={{ margin: "auto 8px" }}>
                    <select
                      className="dropdown setpageSelection show form-control"
                      name="pagination"
                      style={{
                        padding: ".5rem .75rem",
                        borderRadius: "4px",
                      }}
                      onChange={(e) => setDeviceSnoVal(e.target.value)}
                      value={deviceSnoVal}
                    >
                      <option disabled>Select</option>
                      <option value="">All</option>
                      {deviceSno.map((option, index) => {
                        return (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                {totalItems && totalItems > 0 ? (
                  <div>
                    <select
                      className="dropdown setpageSelection show form-control"
                      name="pagination"
                      style={{
                        padding: ".5rem .75rem",
                        borderRadius: "4px",
                      }}
                      onChange={setPagination}
                      value={paginationRow}
                      // onChange={(event) => setPagination(event)}
                    >
                      <option value={10}>show 10 rows</option>
                      <option value={20}>show 20 rows</option>
                      <option value={25}>show 25 rows</option>
                      <option value={50}>show 50 rows</option>
                    </select>
                  </div>
                ) : (
                  <></>
                )}
                {/* <div style={{ margin: "auto 8px" }}>
                    <input
                      type="date"
                      name="date"
                      id="date"
                      className="form-control"
                      style={{ height: "38px" }}
                    />
                  </div> */}

                {/* <div style={{ margin: "auto 8px" }}>
                  <button
                    type="button"
                    className="btn btn-sm bg-white"
                    style={{ border: "solid #2b80ff 1px" }}
                    onClick={() => {
                      navigate("#");
                    }}
                  >
                    <i
                      className="fa fa-th"
                      style={{
                        height: "8px",
                        width: "8px",
                        paddingRight: "16px",
                        color: "#2b80ff",
                      }}
                    ></i>
                  </button>
                </div> */}
                <div>
                  <button
                    type="button"
                    className={`btn excelBtn btn-sm btn-info btn-icon-text ${
                      data.length > 0 ? "" : "disabled"
                    }`}
                    onClick={onBtnExport}
                  >
                    CSV
                    <i className="typcn typcn-download btn-icon-append"></i>
                  </button>
                </div>
                <div style={{ margin: "auto 8px" }}>
                  <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item nav-profile dropdown">
                      <a
                        className="nav-link dropdown-toggle pl-0 pr-0"
                        href="#"
                        data-toggle="dropdown"
                        id="profileDropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-sm bg-white"
                          style={{ border: "solid #2b80ff 1px" }}
                        >
                          <i
                            className="fa fa-th"
                            aria-hidden="true"
                            style={{
                              height: "8px",
                              width: "8px",
                              paddingRight: "16px",
                              color: "#2b80ff",
                            }}
                          ></i>
                        </button>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right navbar-dropdown"
                        aria-labelledby="profileDropdown"
                      >
                        <NavLink to="/raw-data" className="dropdown-item">
                          <i
                            className="fa fa-database text-dark"
                            aria-hidden="true"
                          ></i>{" "}
                          Raw data
                        </NavLink>
                        <NavLink
                          to="/raw-data-labeled"
                          className="dropdown-item"
                        >
                          <i
                            className="fa-solid fa-tag text-dark"
                            aria-hidden="true"
                          ></i>{" "}
                          Labeled Data
                        </NavLink>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <div style={{ margin: "auto 8px" }}>
                    <button
                      type="button"
                      className="btn btn-sm bg-white border"
                      onClick={() => {
                        navigate("/raw-data-graph");
                      }}
                    >
                      <i
                        className="fa fa-line-chart"
                        style={{
                          height: "8px",
                          width: "8px",
                          paddingRight: "16px",
                        }}
                      ></i>
                    </button>
                  </div> */}
                <ul className="navbar-nav navbar-nav-right">
                  <li className="nav-item nav-profile dropdown">
                    <a
                      className="nav-link dropdown-toggle pl-0 pr-0"
                      href="#"
                      data-toggle="dropdown"
                      id="profileDropdown"
                    >
                      <button type="button" className="btn btn-sm bg-white">
                        <i
                          className="fa fa-line-chart"
                          aria-hidden="true"
                          style={{
                            height: "8px",
                            width: "8px",
                            paddingRight: "16px",
                          }}
                        ></i>
                      </button>
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right navbar-dropdown"
                      aria-labelledby="profileDropdown"
                    >
                      {/* <NavLink to="/raw-data-graph" className="dropdown-item">
                        <i
                          className="fa fa-clock text-primary"
                          aria-hidden="true"
                        ></i>{" "}
                        Real Time
                      </NavLink> */}
                      <NavLink
                        to="/raw-data-graph-by-time"
                        className="dropdown-item"
                      >
                        <i
                          className="fa-solid fa-clock text-primary"
                          aria-hidden="true"
                        ></i>{" "}
                        Time Specific Raw
                      </NavLink>
                      <NavLink
                        to="/activity-data-graph-by-time"
                        className="dropdown-item"
                      >
                        <i
                          className="fa-solid fa-clock-rotate-left text-primary"
                          aria-hidden="true"
                        ></i>{" "}
                        Time Specific Labeled
                      </NavLink>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body" style={{ background: "#ffffff" }}>
              {/* On div wrapping Grid a) specify theme CSS className className and b) sets Grid size */}
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: 700 }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={data} // Row Data for Rows
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  paginationPageSize={paginationRow}
                  suppressExcelExport={true}
                  sortingOrder={sortingOrder}
                  enableCellTextSelection={true} // For cell data selection
                  rowSelection={"multiple"} // For row selection
                  suppressRowClickSelection={true} // For row selection
                  onSelectionChanged={onSelectionChanged} // For Getting number of rows selected
                  onGridReady={onGridReady}
                  overlayLoadingTemplate={
                    '<span className="ag-overlay-loading-center">Please wait while your records are loading...</span>'
                  }
                  rowHeight={50}
                  suppressPaginationPanel={true}
                  // pagination={true}
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                />
              </div>
              {totalItems && totalItems > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <div
                        id="order-listing_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="order-listing_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {startItem} to {endItem} of {totalItems}{" "}
                              entries{" "}
                              {/* Replace "10" with the number of items per page */}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="order-listing_paginate"
                            >
                              <ul className="pagination">
                                {/* Render the "Previous" button */}
                                <li
                                  className={`paginate_button page-item ${
                                    currentPage === 1 ? "disabled" : ""
                                  }`}
                                  id="order-listing_previous"
                                  onClick={() =>
                                    handlePageChange(currentPage - 1)
                                  }
                                >
                                  <a
                                    href="#"
                                    aria-controls="order-listing"
                                    data-dt-idx="0"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    <i
                                      className="fa fa-chevron-left"
                                      style={{
                                        height: "4px",
                                        width: "4px",
                                      }}
                                    ></i>
                                  </a>
                                </li>

                                {/* Render the current page number */}
                                <li className="paginate_button page-item active">
                                  <a
                                    href="#"
                                    aria-controls="order-listing"
                                    data-dt-idx="1"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    {currentPage}
                                  </a>
                                </li>

                                {/* Render the "Next" button */}
                                <li
                                  className={`paginate_button page-item ${
                                    totalItems === 0 ||
                                    currentPage * 10 >= totalItems
                                      ? "disabled"
                                      : ""
                                  }`}
                                  id="order-listing_next"
                                  onClick={() =>
                                    handlePageChange(currentPage + 1)
                                  }
                                >
                                  <a
                                    href="#"
                                    aria-controls="order-listing"
                                    data-dt-idx="2"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    <i
                                      className="fa fa-chevron-right"
                                      style={{ height: "4px", width: "4px" }}
                                    ></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <div
                        id="order-listing_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="order-listing_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing{" "}
                              {totalCount ? totalCount - (totalCount - 1) : 0}{" "}
                              to {totalCount ? totalCount : 0} of{" "}
                              {totalCount ? totalCount : 0} entries{" "}
                              {/* Replace "10" with the number of items per page */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 grid-margin stretch-card">
          <div className="card">
            <div
              className="card-body"
              style={{ background: "#ffffff", padding: "0.175rem 1.875rem" }}
            >
              {/* <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                <h5 className="mb-1 mt-4">Apply Parameters </h5>
              </div>
              <div style={{ margin: "12px auto" }}>
                <select
                  className="dropdown setpageSelection show form-control"
                  name="label"
                  onChange={handleFormData}
                  value={fetchRawData.label}
                  style={{
                    padding: ".5rem .75rem",
                    borderRadius: "4px",
                  }}
                >
                  <option value={""}>Select Label</option>
                  <option value="medium intensity">Medium Intensity</option>
                  <option value="low intensity">Low Intensity</option>
                  <option value="high intensity">High Intensity</option>
                </select>
              </div>
              <div style={{ margin: "12px auto" }}>
                <select
                  className="dropdown setpageSelection show form-control"
                  name="dogId"
                  onChange={handleFormData}
                  value={fetchRawData.dogId}
                  style={{
                    padding: ".5rem .75rem",
                    borderRadius: "4px",
                  }}
                >
                  <option value="">Dog Profile</option>
                  <option value="1">Darco Does</option>
                  <option value="2">Coco</option>
                </select>
              </div>
              <div style={{ margin: "12px auto" }}>
                <select
                  className="dropdown setpageSelection show form-control"
                  name="activity"
                  onChange={handleFormData}
                  value={fetchRawData.activity}
                  style={{
                    padding: ".5rem .75rem",
                    borderRadius: "4px",
                  }}
                >
                  <option value="">Activity</option>
                  <option value="Sleeping">Sleeping</option>
                  <option value="Relaxing">Relaxing</option>
                  <option value="Walking on the leash">
                    Walking on the leash
                  </option>
                  <option value="Going for short hike">
                    Going for short hike
                  </option>
                  <option value="Playing fetch in the park">
                    Playing fetch in the park
                  </option>
                  <option value="Playing tug-of-war">Playing tug-of-war</option>
                  <option value="Agility training">Agility training</option>
                  <option value="Disc dog">Disc dog</option>
                </select>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={insertLabelActivity}
                >
                  Apply
                </button>
              </div> */}
              <form
                className="forms-sample"
                autoComplete="off"
                // onSubmit={getData}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                      <h5 className="mb-1 mt-4">Apply Filters</h5>
                    </div>
                    <div className="form-group">
                      <label htmlFor="datePicker">Serial Number/Band Id</label>
                      <select
                        className="dropdown show form-control"
                        name="pagination"
                        style={{
                          padding: ".5rem .75rem",
                          borderRadius: "4px",
                        }}
                        onChange={(e) => setDeviceSnoVal(e.target.value)}
                        value={deviceSnoVal}
                      >
                        <option value="">Select</option>
                        {deviceSno.map((option, index) => {
                          return (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="activity">Activity</label>
                      <select
                        className="dropdown setpageSelection show form-control"
                        name="activity"
                        onChange={handleFormData}
                        value={fetchRawData.activity}
                        style={{
                          padding: ".5rem .75rem",
                          borderRadius: "4px",
                        }}
                      >
                        <option value="">Select Activity</option>
                        <option value="Walking">Walking</option>
                        <option value="Running">Running</option>
                        <option value="Playing">Playing</option>
                        <option value="Jumping">Jumping</option>
                        <option value="Resting">Resting</option>
                        {/* <option value="Training">Training</option> */}
                        <option value="Standing">Standing</option>
                        <option value="Sitting">Sitting</option>
                        <option value="Sleeping">Sleeping</option>
                        <option value="Drinking">Drinking</option>
                        <option value="Eating">Eating</option>
                        <option value="Scratching">Scratching</option>
                        <option value="Licking">Licking</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="label">Intensity</label>
                      <select
                        className="dropdown setpageSelection show form-control"
                        name="label"
                        onChange={handleFormData}
                        value={fetchRawData.label}
                        style={{
                          padding: ".5rem .75rem",
                          borderRadius: "4px",
                        }}
                      >
                        <option value={""}>Select Intensity</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                        <option value="Sedentary">Sedentary</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="datePicker">Date</label>
                      <input
                        type="date"
                        id="datePicker"
                        name="date"
                        max={new Date().toISOString().split("T")[0]}
                        value={fetchRawData.date}
                        onChange={handleFormData}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="startTimePicker">From</label>
                        <TimePicker
                          value={fetchRawData.startDateTime}
                          onChange={(newValue) =>
                            handleFormData(newValue, "startDateTime")
                          }
                          className="form-control"
                          renderInput={(params) => <TextField {...params} />}
                          format="HH:mm:ss"
                          views={["hours", "minutes", "seconds"]}
                          timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                          maxTime={moment().endOf("day")}
                          ampm={false}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                  {/* <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="startTimePicker">From</label>
                      <input
                        type="time"
                        id="startTimePicker"
                        name="startDateTime"
                        value={fetchRawData.startDateTime}
                        onChange={handleFormData}
                        className="form-control"
                        step="0.001"
                      ></input>
                    </div>
                  </div> */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="endDateTime">To</label>
                        <TimePicker
                          value={fetchRawData.endDateTime}
                          onChange={(newValue) =>
                            handleFormData(newValue, "endDateTime")
                          }
                          className="form-control"
                          renderInput={(params) => <TextField {...params} />}
                          format="HH:mm:ss"
                          views={["hours", "minutes", "seconds"]}
                          timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                          maxTime={moment().endOf("day")}
                          ampm={false}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                  {/* <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="endTimePicker">To</label>
                      <input
                        type="time"
                        id="endTimePicker"
                        name="endDateTime"
                        value={fetchRawData.endDateTime}
                        onChange={handleFormData}
                        className="form-control"
                        step="0.001"
                      ></input>
                    </div>
                  </div> */}
                  <div className="form-group">
                    <button
                      // type="submit"
                      type="button"
                      className="btn btn-primary mr-2 px-5 py-3"
                      id="loginBtn"
                      onClick={handleButtonClick}
                    >
                      Filter
                    </button>{" "}
                    <button
                      // type="submit"
                      type="button"
                      className="btn btn-dark mr-2 px-5 py-3"
                      id="loginBtn"
                      onClick={handleResetClick}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RawDataLabeled;
