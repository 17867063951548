import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";

const ChangePassAdmin = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const [showPassowrd, setShowPassowrd] = useState(false);

  const resetAdminPassword = async (event) => {
    event.preventDefault();
    document.getElementById(
      "resetPassBtn"
    ).innerHTML = `<span class="load loading resetPassBtn"></span>`;
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    var BASE_URL = SERVER_URL + `/api/admin/reset-admin-password/${id}`;
    const password = event.target.password.value;
    const new_password = event.target.newPassword.value;
    const confirm_password = event.target.confirmPassword.value;

    const res = await fetch(BASE_URL, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: password,
        new_password: new_password,
        confirm_password: confirm_password,
      }),
    });
    const data = await res.json();
    if (res.ok) {
      setTimeout(() => {
        document.getElementById("resetPassBtn").innerHTML = `Reset Password`;
      }, 500);
      // window.alert(data.message);
      toast.success(data.message);
    } else {
      // window.alert(data.message);
      setTimeout(() => {
        document.getElementById("resetPassBtn").innerHTML = `Reset Password`;
      }, 500);
      toast.error(data.message);
      // document.getElementById("reset").reset();
    }
  };
  function hideShowPassword() {
    let password = document.getElementById("password");
    let new_password = document.getElementById("new_password");
    let confirm_password = document.getElementById("confirm_password");
    if (showPassowrd) {
      password.type = "password";
      new_password.type = "password";
      confirm_password.type = "password";
      setShowPassowrd(false);
    } else {
      password.type = "text";
      new_password.type = "text";
      confirm_password.type = "text";
      setShowPassowrd(true);
    }
  }
  return (
    <>
      <div className="profile-feed">
        <div className="row  mt-3">
          <div className="col-md-12 grid-margin stretch-card">
            <form
              className="forms-sample"
              autoComplete="off"
              onSubmit={resetAdminPassword}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="password">Current Password</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      id="password"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="new_password">New Password</label>
                    <input
                      id="new_password"
                      type="password"
                      name="newPassword"
                      className="form-control"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="form-group">
                    <label htmlFor="confirm_password">Confirm Password</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                      id="confirm_password"
                      placeholder="Password"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="form-check form-check-flat form-check-primary">
                  <label className="form-check-label">
                    Show Password{" "}
                    <input
                      type="checkbox"
                      id="checkBox"
                      className="form-check-input"
                      onClick={() => hideShowPassword()}
                    />
                    <i className="input-helper"></i>
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary mr-2"
                id="resetPassBtn"
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassAdmin;
