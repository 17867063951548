import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

// import { positions, types } from "react-alert";
// import { useAlert } from "react-alert";

const DataTable = ({ refreshVal }) => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  // const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [paginationRow, setPaginationRow] = useState(10); // Set rowData to Array of Objects, one Object per Row

  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const getData = async () => {
    // const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    // let BASE_URL = SERVER_URL + `/api/admin/get-trn-data/${id}`;
    // // Check if cached data exists
    // const cachedData = localStorage.getItem("cachedData");
    // if (cachedData) {
    //   setData(JSON.parse(cachedData));
    //   // return;
    // }
    // const res = await fetch(BASE_URL, {
    //   method: "GET",
    //   headers: {
    //     authorization: `Bearer ${JSON.parse(
    //       localStorage.getItem("logged_res")
    //     )}`,
    //     "Content-Type": "application/json",
    //   },
    // });
    // const result = await res.json();
    // const updatedData = result.data?.map((row, index) => {
    //   return {
    //     ...row,
    //     index: (currentPage - 1) * Object.keys(result).length + index + 1,
    //   };
    // });
    // setData(updatedData);
    // // setData(result.data);
    // // Cache the data
    // localStorage.setItem("cachedData", JSON.stringify(updatedData));
  };

  useEffect(() => {
    getData();
  }, [setData]);
  // }, []);

  useEffect(() => {
    if (refreshVal === true) {
      getData();
    }
  }, [refreshVal]);
  // ========================================[For Api and Data set End]===================================================

  const numberToColor = (val) => {
    if (val === "In Progress") {
      return "badge-info";
    } else if (val === "Success") {
      return "badge-success";
    } else if (val === "Pending") {
      return "badge-warning";
    } else if (val === "Error") {
      return "badge-danger";
    } else {
      return "badge-primary";
    }
  };

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);
  // const alert = useAlert();

  //  =======================================[For getting Id for delete]======================================
  const buttonClicked = (cellValue) => {
    const confirmation = window.confirm(
      "Are you sure? You want to delete this data!"
    );
    if (confirmation) {
      console.log(`${cellValue} Delete This Id`);
    }
    // alert.show(`${cellValue} Delete This Id`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
  };
  //  =======================================[For getting Id for delete]======================================

  // Col Defs For Sample Pending Data Start===============================================
  // =========================================[For Diamond trn_log Data]============================================

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "S.No",
      field: "index",
      // width: 150,
      // For selection
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { headerName: "Dog Info", field: "Dog Info" },
    { headerName: "Dog Info", field: "Dog Info" },
    { headerName: "Dog Info", field: "Dog Info" },
    { headerName: "Dog Info", field: "Dog Info" },
    { headerName: "Dog Info", field: "Dog Info" },
    { headerName: "Dog Info", field: "Dog Info" },
    { headerName: "Dog Info", field: "Dog Info" },
    { headerName: "Dog Info", field: "Dog Info" },
    { headerName: "Dog Info", field: "Dog Info" },
    { headerName: "Dog Info", field: "Dog Info" },
    { headerName: "Dog Info", field: "Dog Info" },
  ]);

  // ==============================================[For selected Data]=========================================
  const [selectedData, setSelectedData] = useState([]);
  const [rmvBtn, setRmvBtn] = useState(false);
  //   console.log(selectedData);
  const storedSelected = selectedData.map((sel) => {
    return sel.data.id;
  });
  console.log(storedSelected);

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 1) {
      setRmvBtn(true);
    }
    if (rowCount < 2) {
      setRmvBtn(false);
    }
    console.log(event.api.getSelectedNodes());
    setSelectedData(event.api.getSelectedNodes());
    // window.alert("selection changed, " + rowCount + " rows selected");
  }, []);
  // ==============================================[For selected data End]=========================================

  // ============================================[For Remove selected data Start]====================================
  const removeSelected = useCallback(() => {
    const selectedRowNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedRowNodes.map(function (rowNode) {
      return rowNode.data.id;
    });
    const confirmation = window.confirm(
      "Are you sure? You want to delete multiple data!"
    );
    if (confirmation) {
      const filteredData = data.filter(function (dataItem) {
        return selectedIds.indexOf(dataItem.id) < 0;
      });
      setData(filteredData);
      setRmvBtn(false);
    }
  }, [data]);
  // ============================================[For Remove selected data End]====================================

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // make every column sortable
    sortable: true,
    // make every column filter
    filter: true,
    filterParams: {
      buttons: ["reset", "apply"],
      // debounceMs: 100,
    },
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,
  }));

  // Example load data from sever
  // useEffect(() => {
  //   fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
  //     .then((result) => result.json())

  //     .then((rowData) => setRowData(rowData));
  // }, []);

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  // window.addEventListener("error", (e) => {
  //   // eslint-disable-next-line no-console
  //   e.preventDefault();
  //   console.log(e);
  // });
  const [error, setError] = useState(null);

  useEffect(() => {
    window.addEventListener("error", handleGlobalError);
    return () => {
      window.removeEventListener("error", handleGlobalError);
    };
  }, []);

  const handleGlobalError = (event) => {
    event.preventDefault();
    setError(event.error);
  };

  if (error) {
    // You can log the error to the console if desired
    console.error(error);

    // Render a fallback UI or a custom error message
    // return <div>Oops! Something went wrong.</div>;
  }
  return (
    <div>
      <div className="card-body">
        <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
          <h4 className="table_title card-title mb-1">
            Manage Table {refreshVal}
          </h4>
          <div className="manageTablesOptions">
            {/* <div>
                    {rmvBtn && (
                      <button
                        onClick={removeSelected}
                        className="btn removeAllBtn btn-sm btn-outline-danger btn-icon-text"
                      >
                        Remove Selected
                        <i
                          className="fa fa-trash btn-icon-append"
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div> */}
            <select
              className="dropdown setpageSelection show form-control"
              name="pagination"
              style={{
                padding: ".5rem .75rem",
                borderRadius: "4px",
              }}
              onChange={(event) => setPagination(event)}
            >
              <option value={10}>show 10 rows</option>
              <option value={20}>show 20 rows</option>
              <option value={25}>show 25 rows</option>
              <option value={50}>show 50 rows</option>
            </select>
            <div>
              <button
                type="button"
                className="btn excelBtn btn-sm btn-info btn-icon-text"
                onClick={onBtnExport}
              >
                Excel
                <i className="typcn typcn-download btn-icon-append"></i>
              </button>
            </div>
          </div>
        </div>

        {/* On div wrapping Grid a) specify theme CSS className className and b) sets Grid size */}
        <div className="ag-theme-alpine" style={{ width: "100%", height: 680 }}>
          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            rowData={data} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            paginationPageSize={paginationRow}
            suppressExcelExport={true}
            sortingOrder={sortingOrder}
            enableCellTextSelection={true} // For cell data selection
            rowSelection={"multiple"} // For row selection
            suppressRowClickSelection={true} // For row selection
            onSelectionChanged={onSelectionChanged} // For Getting number of rows selected
            rowHeight={50}
            pagination={true}
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          />
        </div>
      </div>
    </div>
  );
};

export default DataTable;
