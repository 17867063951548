import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import * as XLSX from "xlsx";
const SampleDataTable = () => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [paginationRow, setPaginationRow] = useState(10); // Set rowData to Array of Objects, one Object per Row

  // ========================================[For Api and Data set Start]===================================================

  const [data, setData] = useState([]);
  // const [status, setStatus] = useState("");
  const [diamondId, setDiamondId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // const getData = async () => {
  //   const id = localStorage.getItem("adminId")?.replace(/"/g, "");
  //   let BASE_URL =
  //     SERVER_URL +
  //     `/api/admin/get-trn-data-search?status=${status}&page=${currentPage}&diamond_id=${diamondId}`;

  //   const res = await fetch(BASE_URL, {
  //     method: "GET",
  //     headers: {
  //       authorization: `Bearer ${JSON.parse(
  //         localStorage.getItem("logged_res")
  //       )}`,
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   const result = await res.json();

  //   // setData(result.data);
  //   const updatedData = result.data?.map((row, index) => {
  //     return {
  //       ...row,
  //       index: (currentPage - 1) * Object.keys(result).length + index + 1,
  //     };
  //   });
  //   setData(updatedData);
  // };

  // useEffect(() => {
  //   getData();
  // }, [status, diamondId, currentPage]);
  const [size, setSize] = useState(10);
  useEffect(() => {
    const getData = async () => {
      const res = await fetch(
        `https://random-data-api.com/api/v2/users?size=${size}&response_type=json`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const result = await res.json();
      // console.log(typeof result);

      // setData([result]);
      // const updatedData = result.map((row, index) => {
      //   return {
      //     ...row,
      //     index: (currentPage - 1) * Object.keys(result).length + index + 1,
      //   };
      // });

      const updatedData = result?.map((row, index) => {
        return {
          ...row,
          index: (currentPage - 1) * Object.keys(result).length + index + 1,
        };
      });
      setData(updatedData);
      //   console.log(result);
      // console.log(updatedData);
      // setData(result || [result]);

      // console.log(typeof data);
    };
    getData();
  }, [size]);

  const [columnDefs, setColumnDefs] = useState([
    // { headerName: "Device Name", field: "DeviceName" },
    {
      headerName: "S.No",
      field: "index",
      width: 150,
      // For selection
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    { headerName: "First Name", field: "first_name" },
    { field: "id", width: 150 },
    {
      headerName: "Status",
      field: "subscription.status",
      cellRenderer: (params) => (
        <label className={`badge ${numberToColor(params.value)}`}>
          {params.value}
        </label>
      ),
      //   cellStyle: cellStyle,
    },
    { field: "email" },
    {
      headerName: "Avatar",
      field: "avatar",
      cellRenderer: (params) => <img height="50px" alt="" src={params.value} />,
    },
    {
      headerName: "Gender",
      field: "gender",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "State",
      field: "address.state",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Actions",
      field: "id",
      minWidth: 220,

      cellRenderer: (params) => (
        <>
          <button
            onClick={() => buttonClicked(params.value)}
            className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
          >
            Edit
            <i className="typcn typcn-edit  btn-icon-append"></i>
          </button>{" "}
          <button
            onClick={() => buttonClicked(params.value)}
            className="btn btn-sm btn-outline-danger btn-icon-text"
          >
            Delete
            <i className="fa fa-trash btn-icon-append" aria-hidden="true"></i>
          </button>
        </>
      ),
    },
  ]);

  // ========================================[For Api and Data set End]===================================================

  const numberToColor = (val) => {
    if (val === "Idle") {
      return "badge-info";
    } else if (val === "Active") {
      return "badge-success";
    } else if (val === "Pending") {
      return "badge-warning";
    } else if (val === "Blocked") {
      return "badge-danger";
    } else {
      return "badge-primary";
    }
  };

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);

  //  =======================================[For getting Id for delete Start]======================================
  const buttonClicked = (cellValue) => {
    const confirmation = window.confirm(
      "Are you sure? You want to delete this data!"
    );
    if (confirmation) {
      console.log(`${cellValue} Delete This Id`);
    }
    // alert.show(`${cellValue} Delete This Id`, {
    //   position: positions.TOP_CENTER,
    //   type: types.ERROR,
    // });
  };

  // ==============================================[For selected Data]=========================================
  const [selectedData, setSelectedData] = useState([]);
  const [rmvBtn, setRmvBtn] = useState(false);
  //   console.log(selectedData);
  const storedSelected = selectedData.map((sel) => {
    return sel.data.id;
  });
  //   console.log(storedSelected);

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 1) {
      setRmvBtn(true);
    }
    if (rowCount < 2) {
      setRmvBtn(false);
    }
    console.log(event.api.getSelectedNodes());
    setSelectedData(event.api.getSelectedNodes());
    // window.alert("selection changed, " + rowCount + " rows selected");
  }, []);
  // ==============================================[For selected data End]=========================================

  // ============================================[For Remove selected data Start]====================================
  const removeSelected = useCallback(() => {
    const selectedRowNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedRowNodes.map(function (rowNode) {
      return rowNode.data.id;
    });
    const confirmation = window.confirm(
      "Are you sure? You want to delete multiple data!"
    );
    if (confirmation) {
      const filteredData = data.filter(function (dataItem) {
        return selectedIds.indexOf(dataItem.id) < 0;
      });
      setData(filteredData);
      setRmvBtn(false);
    }
  }, [data]);
  // ============================================[For Remove selected data End]====================================

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // make every column sortable
    sortable: true,
    // make every column filter
    // filter: true,
    // filterParams: {
    //   buttons: ["reset", "apply"],
    //   debounceMs: 100,
    // },
    // // enable floating filters by default
    // floatingFilter: true,
    // make columns resizable
    resizable: true,
  }));

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const [speed, setSpeed] = useState(1);

  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  var status = "all";
  const externalFilterChanged = useCallback((event) => {
    // event.preventDefault();
    status = event.target.value;
    console.log(event.target.value);
    gridRef.current.api.onFilterChanged();
  }, []);

  const isExternalFilterPresent = useCallback(() => {
    // if ageType is not everyone, then we are filtering
    return status !== "all";
  }, []);

  const doesExternalFilterPass = useCallback(
    (node) => {
      if (node.data) {
        switch (status) {
          case "Active":
            return node.data.subscription.status == "Active";
          case "Blocked":
            return node.data.subscription.status == "Blocked";
          case "Pending":
            return node.data.subscription.status == "Pending";
          case "Idle":
            return node.data.subscription.status == "Idle";
          default:
            return true;
        }
      }
      return true;
    },
    [status]
  );
  return (
    <div className="content-wrapper">
      <div className="row mt-3">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body" style={{ background: "#ffffff" }}>
              <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                <h4 className="table_title card-title mb-1">Manage Table</h4>
                <div className="manageTablesOptions">
                  {/* <div>
                      {rmvBtn && (
                        <button
                          onClick={removeSelected}
                          className="btn removeAllBtn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Remove Selected
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      )}
                    </div>
                    <select
                      className="dropdown setpageSelection show form-control"
                      name="pagination"
                      style={{
                        padding: ".5rem .75rem",
                        borderRadius: "4px",
                      }}
                      onChange={(event) => setPagination(event)}
                    >
                      <option value={10}>show 10 rows</option>
                      <option value={20}>show 20 rows</option>
                      <option value={25}>show 25 rows</option>
                      <option value={50}>show 50 rows</option>
                    </select> */}
                  {/* For Import */}
                  {/* <div className="form-group">
                      <input
                        type="file"
                        name="image"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        className="file-upload-default"
                      />
                      <div className="input-group col-xs-12">
                        <span className="input-group-append">
                          <button
                            className="btn excelBtn btn-sm btn-info btn-icon-text"
                            type="button"
                            onClick={handleUploadButtonClick}
                          >
                            Import
                            <i className="typcn typcn-upload btn-icon-append"></i>
                          </button>
                        </span>
                      </div>
                    </div> */}
                  {/* <div>
                      <button
                        type="button"
                        className="btn excelBtn btn-sm btn-info btn-icon-text"
                        onClick={onBtnExport}
                      >
                        Excel
                        <i className="typcn typcn-download btn-icon-append"></i>
                      </button>
                    </div> */}
                    <select
                    className="dropdown setpageSelection show form-control"
                    name="pagination"
                    style={{
                      padding: ".5rem .75rem",
                      borderRadius: "4px",
                    }}
                    onChange={(event) => externalFilterChanged(event)}
                  >
                    <option value={"all"}>all</option>
                    <option value={"Active"}>Active</option>
                    <option value={"Blocked"}>Blocked</option>
                    <option value={"Pending"}>Pending</option>
                    <option value={"Idle"}>Idle</option>
                  </select>

                  <label>
                    <input
                      type="radio"
                      name="filter"
                      id="180"
                      onChange={() => externalFilterChanged("180")}
                    />
                    180
                  </label>
                </div>
              </div>

              {/* On div wrapping Grid a) specify theme CSS className className and b) sets Grid size */}
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: 600 }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={data} // Row Data for Rows
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  paginationPageSize={paginationRow}
                  suppressExcelExport={true}
                  sortingOrder={sortingOrder}
                  rowSelection={"multiple"} // For row selection
                  suppressRowClickSelection={true} // For row selection
                  onSelectionChanged={onSelectionChanged} // For Getting number of rows selected
                  rowHeight={50}
                  isExternalFilterPresent={isExternalFilterPresent}
                  doesExternalFilterPass={doesExternalFilterPass}
                  // pagination={true}
                  suppressPaginationPanel={true}
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <div
                      id="order-listing_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      {/* <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div
                            className="dataTables_length"
                            id="order-listing_length"
                          >
                            <label>
                              Show{" "}
                              <select
                                name="order-listing_length"
                                aria-controls="order-listing"
                                className="custom-select custom-select-sm form-control"
                              >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="-1">All</option>
                              </select>{" "}
                              entries
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                        <div
                          id="order-listing_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              aria-controls="order-listing"
                                onChange={(e) => setStatus(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div
                          id="order-listing_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search"
                              aria-controls="order-listing"
                              //   onChange={(e) => setDiamondId(e.target.value)}
                            />
                          </label>
                        </div>
                      </div>
                      </div> */}
                      {/* <div className="row">
                        <div className="col-sm-12">
                          <table
                            id="order-listing"
                            className="table dataTable no-footer"
                            role="grid"
                            aria-describedby="order-listing_info"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="sorting_asc"
                                  tabIndex="0"
                                  aria-controls="order-listing"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-sort="ascending"
                                  aria-label="Order #: activate to sort column descending"
                                  style={{ width: "115.922px" }}
                                >
                                  S.no.
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="order-listing"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Purchased On: activate to sort column ascending"
                                  style={{ width: "192.375px" }}
                                >
                                  Email
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="order-listing"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Customer: activate to sort column ascending"
                                  style={{ width: "142.719px" }}
                                >
                                  Name
                                </th>

                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="order-listing"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Base Price: activate to sort column ascending"
                                  style={{ width: "153.438px" }}
                                >
                                  Id
                                </th>

                                <th
                                  className="sorting"
                                  tabIndex="0"
                                  aria-controls="order-listing"
                                  rowSpan="1"
                                  colSpan="1"
                                  aria-label="Status: activate to sort column ascending"
                                  style={{ width: "113.672px" }}
                                >
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.length > 0 ? (
                                data?.map((items, index) => {
                                  return (
                                    <tr role="row" className="odd" key={index}>
                                      <td className="sorting_1">{index + 1}</td>
                                      <td>{items.email}</td>
                                      <td>
                                        {items.first_name +
                                          " " +
                                          items.last_name}
                                      </td>
                                      <td>{items.id}</td>
                                      <td>
                                        <label
                                          className={`badge
                                            ${
                                              items.subscription.status ===
                                              "Active"
                                                ? "badge-success"
                                                : items.subscription.status ===
                                                  "Blocked"
                                                ? "badge-danger"
                                                : items.subscription.status ===
                                                  "Pending"
                                                ? "badge-warning"
                                                : items.subscription.status ===
                                                    "Pending" &&
                                                  items.subscription
                                                    .process_flag === 1
                                                ? "badge-info"
                                                : "badge-primary"
                                            }
                                          `}
                                        >
                                          {items.subscription.status}
                                        </label>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr className="text-center">
                                  <td colSpan="12">No Result Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <div
                            className="dataTables_info"
                            id="order-listing_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing 1 to 10 of 10 entries
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="order-listing_paginate"
                          >
                            <ul className="pagination">
                              {size && size === 10 ? (
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="order-listing_previous"

                                  //   onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                  <a
                                    href="#"
                                    aria-controls="order-listing"
                                    data-dt-idx="0"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    <i
                                      className="fa fa-chevron-left"
                                      style={{
                                        height: "4px",
                                        width: "4px",
                                        color: "grey",
                                      }}
                                    ></i>{" "}
                                  </a>
                                </li>
                              ) : (
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="order-listing_previous"
                                  onClick={() =>
                                    setSize(size > 10 ? size - 10 : 10)
                                  }
                                  //   onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                  <a
                                    href="#"
                                    aria-controls="order-listing"
                                    data-dt-idx="0"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    <i
                                      className="fa fa-chevron-left"
                                      style={{ height: "4px", width: "4px" }}
                                    ></i>{" "}
                                  </a>
                                </li>
                              )}
                              <li className="paginate_button page-item active">
                                <a
                                  href="#"
                                  aria-controls="order-listing"
                                  data-dt-idx="1"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  {size ? size / 10 : 1}
                                </a>
                              </li>
                              <li
                                className="paginate_button page-item next disabled"
                                id="order-listing_next"
                                onClick={() => setSize(size + 10)}
                                //   onClick={() => setCurrentPage(currentPage + 1)}
                              >
                                <a
                                  href="#"
                                  aria-controls="order-listing"
                                  data-dt-idx="2"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  <i
                                    className="fa fa-chevron-right"
                                    style={{ height: "4px", width: "4px" }}
                                  ></i>{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleDataTable;
