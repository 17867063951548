import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

// import { positions, types } from "react-alert";
// import { useAlert } from "react-alert";

const Device = () => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  // const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row
  const [paginationRow, setPaginationRow] = useState(10); // Set rowData to Array of Objects, one Object per Row

  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  // gets called once, no dependencies, loads the grid data
  useEffect(() => {
    fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => setErrorFilter(data));
  }, []);
  const getData = async () => {
    // const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    // let BASE_URL = SERVER_URL + `/api/admin/get-trn-data/${id}`;
    // // // Check if cached data exists
    // const cachedData = localStorage.getItem("cachedData");
    // if (cachedData) {
    //   setData(JSON.parse(cachedData));
    //   //   return;
    // }
    // const res = await fetch(BASE_URL, {
    //   method: "GET",
    //   headers: {
    //     authorization: `Bearer ${JSON.parse(
    //       localStorage.getItem("logged_res")
    //     )}`,
    //     "Content-Type": "application/json",
    //   },
    // });
    // const result = await res.json();
    // setData(result.data);
    // // // Cache the data
    // localStorage.setItem("cachedData", JSON.stringify(result.data));
  };
  useEffect(() => {
    getData();
  }, [setData]);

  // =========================[For Sample Data Stored Combining trn_main and trn_log End]==============================
  // ========================================[For Api and Data set End]===================================================
  // ========================================[For Filter Error Start]===================================================
  const [errorFilter, setErrorFilter] = useState([]);
  function filterFun() {
    data?.map(() => {
      const datas = data?.filter(
        (d) => d.status === "Error"
        // console.log(blog.category === activeCategory)
      );
      const updatedData = datas?.map((row, index) => {
        return {
          ...row,
          index: (currentPage - 1) * Object.keys(datas).length + index + 1,
        };
      });
      setErrorFilter(updatedData);
      console.log(datas);
    });
  }
  useEffect(() => {
    filterFun();
  }, [data]);
  // ========================================[For Filter Error End]===================================================

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);
  // const alert = useAlert();

  //  =======================================[For getting Id for delete]======================================

  // const [columnDefs, setColumnDefs] = useState([
  //   // { headerName: "Device Name", field: "DeviceName" },
  //   {
  //     headerName: "S.No",
  //     field: "index",
  //     width: 150,
  //     // For selection
  //     headerCheckboxSelection: true,
  //     checkboxSelection: true,
  //     showDisabledCheckboxes: true,
  //   },
  //   { headerName: "First Name", field: "first_name" },
  //   { field: "id", width: 150 },
  //   {
  //     headerName: "Status",
  //     field: "subscription.status",
  //     cellRenderer: (params) => (
  //       <label className={`badge ${numberToColor(params.value)}`}>
  //         {params.value}
  //       </label>
  //     ),
  //     //   cellStyle: cellStyle,
  //   },
  //   { field: "email" },
  //   // {
  //   //   headerName: "Avatar",
  //   //   field: "avatar",
  //   //   cellRenderer: (params) => <img height="50px" alt="" src={params.value} />,
  //   // },
  //   {
  //     headerName: "Gender",
  //     field: "gender",
  //     cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
  //   },
  //   {
  //     headerName: "State",
  //     field: "address.state",
  //     cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
  //   },
  //   {
  //     headerName: "Actions",
  //     field: "id",
  //     minWidth: 220,

  //     cellRenderer: (params) => (
  //       <>
  //         <button
  //           onClick={() => buttonClicked(params.value)}
  //           className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
  //         >
  //           Edit
  //           <i className="typcn typcn-edit  btn-icon-append"></i>
  //         </button>{" "}
  //         <button
  //           onClick={() => buttonClicked(params.value)}
  //           className="btn btn-sm btn-outline-danger btn-icon-text"
  //         >
  //           Delete
  //           <i className="fa fa-trash btn-icon-append" aria-hidden="true"></i>
  //         </button>
  //       </>
  //     ),
  //   },
  // ]);
  // Col Defs For Sample Pending Data Start===============================================
  // =========================================[For Diamond trn_log Data]============================================
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "S.No",
      field: "index",
      minWidth: 205,
      // For selection
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    // { headerName: "Id", field: "id", width: 150 },
    { headerName: "Device Name", field: "device_name", minWidth: 205 },
    { headerName: "Firmware", field: "firmware", minWidth: 205 },
    {
      headerName: "Last Active",
      minWidth: 350,
      field: "last_active",
    },
    {
      headerName: "Battery",
      field: "battery",
      minWidth: 350,
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "BLE",
      field: "ble",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "Wifi",
      field: "wifi",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "TMP",
      field: "tmp",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "ACC",
      // field: "acc",
      field: "age",
      cellRenderer: (params) => (
        <div style={{ textAlign: "center", padding: "12px" }}>
          {params.value > 21 ? (
            <div className="green-dot"></div>
          ) : (
            <div className="red-dot"></div>
          )}
        </div>
      ),
    },
    {
      headerName: "State",
      field: "state",
      cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
    {
      headerName: "On/Off",
      field: "age",
      cellRenderer: (params) => (
        <div style={{ textAlign: "center", padding: "12px" }}>
          <input
            type="checkbox"
            id={`toggle ${params.node.rowIndex + 1}`}
            className="toggle-input"
            defaultChecked={params.node.rowIndex + 1 < 5 ? true : false}
          />
          <label
            htmlFor={`toggle ${params.node.rowIndex + 1}`}
            className="toggle-label"
          ></label>
        </div>
      ),
      // cellRenderer: (params) => (params.value !== "" ? params.value : "-"),
    },
  ]);

  // Col Defs For Sample Pending Data End===============================================
  // ==============================================[For selected Data]=========================================
  const [selectedData, setSelectedData] = useState([]);
  const [rmvBtn, setRmvBtn] = useState(false);
  //   console.log(selectedData);
  const storedSelected = selectedData.map((sel) => {
    return sel.data.diamond_id;
  });
  console.log(storedSelected);

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 1) {
      setRmvBtn(true);
    }
    if (rowCount < 2) {
      setRmvBtn(false);
    }
    console.log(event.api.getSelectedNodes());
    setSelectedData(event.api.getSelectedNodes());
    // window.alert("selection changed, " + rowCount + " rows selected");
  }, []);
  // ==============================================[For selected data End]=========================================

  // ============================================[For Remove selected data Start]====================================
  const removeSelected = useCallback(() => {
    const selectedRowNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedRowNodes.map(function (rowNode) {
      return rowNode.data.id;
    });
    const confirmation = window.confirm(
      "Are you sure? You want to delete multiple data!"
    );
    if (confirmation) {
      const filteredData = data.filter(function (dataItem) {
        return selectedIds.indexOf(dataItem.id) < 0;
      });
      setData(filteredData);
      setRmvBtn(false);
    }
  }, [data]);
  // ============================================[For Remove selected data End]====================================

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // make every column sortable
    sortable: true,
    // make every column filter
    filter: true,
    filterParams: {
      buttons: ["reset", "apply"],
      // debounceMs: 100,
    },
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,
  }));

  // Example load data from sever
  // useEffect(() => {
  //   fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
  //     .then((result) => result.json())

  //     .then((rowData) => setRowData(rowData));
  // }, []);

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleButtonClick = () => {
    getData();
  };
  return (
    <div className="content-wrapper">
      <div className="row mt-3">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {/* <div>
              <input type="checkbox" id="toggle" className="toggle-input" />
              <label for="toggle" className="toggle-label"></label>
              </div> */}
              {/* <div className="green-dot"></div>
              <div className="red-dot"></div> */}

              <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                <h4 className="table_title card-title mb-1">
                  Device{" "}
                  <span>
                    <button
                      className="btn btn-sm bg-white btn-icon-text border"
                      onClick={handleButtonClick}
                    >
                      <i
                        className="typcn typcn-refresh"
                        style={{ height: "4px", width: "4px" }}
                      ></i>{" "}
                    </button>
                  </span>
                </h4>
                <div className="manageTablesOptions">
                  {/* <div>
                    {rmvBtn && (
                      <button
                        onClick={removeSelected}
                        className="btn removeAllBtn btn-sm btn-outline-danger btn-icon-text"
                      >
                        Remove Selected
                        <i
                          className="fa fa-trash btn-icon-append"
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div> */}

                  {/* <div>
                    <button
                      type="button"
                      className="btn excelBtn btn-sm btn-info btn-icon-text"
                      onClick={onBtnExport}
                    >
                      Excel
                      <i className="typcn typcn-download btn-icon-append"></i>
                    </button>
                  </div> */}
                </div>
              </div>

              {/* On div wrapping Grid a) specify theme CSS className className and b) sets Grid size */}
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: 680 }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={errorFilter} // Row Data for Rows
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  paginationPageSize={paginationRow}
                  suppressExcelExport={true}
                  sortingOrder={sortingOrder}
                  enableCellTextSelection={true} // For cell data selection
                  rowSelection={"multiple"} // For row selection
                  suppressRowClickSelection={true} // For row selection
                  onSelectionChanged={onSelectionChanged} // For Getting number of rows selected
                  rowHeight={50}
                  pagination={true}
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Device;
