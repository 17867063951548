import WebRoutes from "./Components/Routes/WebRoutes";
import "./App.css";
import { Toaster } from "react-hot-toast";
function App() {
  return (
    <>
      <WebRoutes />
      <Toaster
        toastOptions={{
          className: "",
          style: {
            borderRadius: "0px",
          },
        }}
      />
    </>
  );
}

export default App;
