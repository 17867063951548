import React, { useState, useEffect, useRef } from "react";

import toast from "react-hot-toast";
const AdminUpdate = ({sendDataToParent, adName, adPhone, adImage, adEmail }) => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const [adminData, setAdminData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
  });
  const [image, setImage] = useState("");

  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setAdminData({ ...adminData, [name]: value });
    // console.log({ ...adminData, [name]: value });
  };

  const handleImageData = (e) => {
    // console.log(e);
    setImage(e.target.files[0]);
    console.log(e.target.files[0]);
  };
  const updateData = async (e) => {
    e.preventDefault();

    document.getElementById(
      "saveChangesBtn"
    ).innerHTML = `<span class="load loading saveChangesBtn"></span>`;

    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    var BASE_URL = SERVER_URL + `/api/admin/update/${id}`;
    if (!adminData.name || !adminData.phone) {
      return false;
    }
    const { name, phone } = adminData;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", adEmail);
    formData.append("phone", phone);
    formData.append("profile_image", image || ("profile_image", adImage));
    console.log(formData);
    const res = await fetch(BASE_URL, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
      },
      body: formData,
    });
    const data = await res.json();
    if (res.ok) {
      sendDataToParent(true);
      // window.alert(data.message);
      setTimeout(() => {
        document.getElementById("saveChangesBtn").innerHTML = `Save Changes`;
      }, 500);
      toast.success("Updated Admin Details");
    } else {
      // window.alert(data.message);
      toast.error(`${data.message}`);

      setTimeout(() => {
        document.getElementById("saveChangesBtn").innerHTML = `Save Changes`;
      }, 500);
      // document.getElementById("reset").reset();
      // setImage("");
    }
  };
  const fileInputRef = useRef(null);
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <div className="profile-feed">
        <div className="row  mt-3">
          <div className="col-md-12 grid-margin stretch-card">
            <form
              className="forms-sample"
              autoComplete="off"
              encType="multipat/form-data"
              onSubmit={updateData}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputUsername1">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputUsername1"
                      placeholder="Username"
                      name="name"
                      defaultValue={adminData.name || (adminData.name = adName)}
                      onChange={handleFormData}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      placeholder="Phone"
                      name="phone"
                      defaultValue={
                        adminData.phone || (adminData.phone = adPhone)
                      }
                      onChange={handleFormData}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="image">Profile Image</label>
                    <input
                      type="file"
                      name="image"
                      id="image"
                      accept=".jpg, .jpeg, .png, .svg"
                      ref={fileInputRef}
                      onChange={handleImageData}
                      className="file-upload-default"
                    />
                    <div className="input-group col-xs-12">
                      <input
                        type="text"
                        className="form-control file-upload-info"
                        readOnly
                        id="file-upload-info"
                        style={{ cursor: "pointer" }}
                        placeholder={image ? image.name : "Upload Image"}
                        onChange={handleImageData}
                        // placeholder="Upload Image"
                        onClick={handleUploadButtonClick}
                      />
                      <span className="input-group-append">
                        <button
                          className="file-upload-browse btn btn-primary"
                          type="button"
                          onClick={handleUploadButtonClick}
                        >
                          Upload
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary mr-2"
                id="saveChangesBtn"
              >
                Save Changes
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUpdate;
