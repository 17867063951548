import React from "react";

function AdminSetting() {
  return (
    <div className="content-wrapper">
      <div className="row  mt-3">
        <div className="col-xl-12 d-flex grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-wrap justify-content-between">
                <h4 className="card-title mb-3">Admin Settings</h4>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="text-secondary mb-1 font-weight-bold">
                        Settings
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSetting;
