import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Protected from "./Protected";

import NotFound from "../NotFound/NotFound";
import Login from "../Auth/Login";
import ForgotPassword from "../Auth/ForgotPassword";
import DashLayout from "../Dashboard/DashLayout";
import Dashboard from "../Dashboard/Dashboard";
import AdminProfile from "../Dashboard/Admin/AdminProfile";
import AddMember from "../Dashboard/Admin/AddMember";
import AdminSetting from "../Dashboard/Admin/AdminSetting";
import VerificationPageAdmin from "../Dashboard/Admin/VerificationPageAdmin";
import Footer from "../Dashboard/Layout/Footer";
import ResetPassword from "../Auth/ResetPassword";
import RawData from "../Dashboard/DataTables/RawData";
import DogProfile from "../Dashboard/DataTables/DogProfile";
import Device from "../Dashboard/DataTables/Device";
import RawDataGraph from "../Dashboard/DataTables/RawDataGraph";
import AddDog from "../Dashboard/CreateDogProfile/AddDogProfile";
import UpdateDogProfile from "../Dashboard/CreateDogProfile/UpdateDogProfile";
import SampleDataTable from "../Dashboard/DataTables/SampleDataTable";
import RawDataGraphByTime from "../Dashboard/DataTables/RawDataGraphByTime";
import RawDataLabeled from "../Dashboard/DataTables/RawDataLabeled";
import ActivityDataGraphByTime from "../Dashboard/DataTables/ActivityDataGraphByTime";
// import SampleTable from "../Dashboard/DataTables/SampleTable";
import StoreStatusTimeStamps from "../Dashboard/DataTables/StoreStatusTimeStamps";
import StoreDeviceTimeStamps from "../Dashboard/DataTables/StoreDeviceTimeStamps";
function WebRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route
          exact
          path="/reset-password/:access_token"
          element={<ResetPassword />}
        />
        <Route
          exact
          path="/verification-page/:access_token"
          element={
            <>
              <VerificationPageAdmin />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <Protected>
              <DashLayout>
                <Dashboard />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/sample-data-table"
          element={
            <Protected>
              <DashLayout>
                <SampleDataTable />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/raw-data"
          element={
            <Protected>
              <DashLayout>
                <RawData />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/raw-data-labeled"
          element={
            <Protected>
              <DashLayout>
                <RawDataLabeled />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/raw-data-graph"
          element={
            <Protected>
              <DashLayout>
                <RawDataGraph />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/raw-data-graph-by-time"
          element={
            <Protected>
              <DashLayout>
                <RawDataGraphByTime />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/activity-data-graph-by-time"
          element={
            <Protected>
              <DashLayout>
                <ActivityDataGraphByTime />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/dog-profile"
          element={
            <Protected>
              <DashLayout>
                <DogProfile />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/device"
          element={
            <Protected>
              <DashLayout>
                <Device />
              </DashLayout>
            </Protected>
          }
        />
        {/* <Route
          exact
          path="/sampletable"
          element={
            <Protected>
              <DashLayout>
                <SampleTable />
              </DashLayout>
            </Protected>
          }
        /> */}
        <Route
          exact
          path="/store-status-timestamps"
          element={
            <Protected>
              <DashLayout>
                <StoreStatusTimeStamps />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/store-device-timestamps"
          element={
            <Protected>
              <DashLayout>
                <StoreDeviceTimeStamps />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/admin-profile"
          element={
            <Protected>
              <DashLayout>
                <AdminProfile />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/add-member"
          element={
            <Protected>
              <DashLayout>
                <AddMember />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/create-dog-profile"
          element={
            <Protected>
              <DashLayout>
                <AddDog />
              </DashLayout>
            </Protected>
          }
        />
        <Route
          exact
          path="/update-dog-profile/:unique_id/:band_id"
          element={
            <Protected>
              <DashLayout>
                <UpdateDogProfile />
              </DashLayout>
            </Protected>
          }
        />

        <Route
          exact
          path="/admin-setting"
          element={
            <Protected>
              <DashLayout>
                <AdminSetting />
              </DashLayout>
            </Protected>
          }
        />

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default WebRoutes;
