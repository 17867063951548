import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import toast from "react-hot-toast";
import moment from "moment";
import { TextField } from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const StoreStatusTimeStamps = () => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [paginationRow, setPaginationRow] = useState(10); // Set rowData to Array of Objects, one Object per Row
  const [data, setData] = useState([]);
  const [deviceSno, setDeviceSno] = useState([]);
  const [deviceSnoVal, setDeviceSnoVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const PIPELINE_SERVER_URL = process.env.REACT_APP_PIPELINE_SERVER_URL;
  const startItem = (currentPage - 1) * paginationRow + 1;
  const endItem = Math?.min(startItem + data?.length - 1, totalItems);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fetchRawData, setFetchRawData] = useState({
    startDateTime: "",
    endDateTime: "",
    date: "",
    phone: "",
    label: "",
    activity: "",
    dogId: "",
  });
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  let name, value;
  const handleFormData = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;

    setFetchRawData({ ...fetchRawData, [name]: value });
    // console.log({ ...fetchRawData, [name]: value });
  };
  const getData = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    toast.loading("Loading... Please wait!");
    let BASE_URL = PIPELINE_SERVER_URL + `/pipeline-one-data`;
    // let BASE_URL = SERVER_URL + `/api/admin/timestamps/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
      },
    });
    const result = await res.json();
    toast.dismiss();
    if (gridRef.current) {
      gridRef.current.api.hideOverlay();
    }
    if (res.ok) {
      if (result?.length > 0) {
        toast.success(result?.message || "Fetched data successfully!");
        const modifiedData =
          result?.flatMap((item) =>
            item.time_stamps.map((ts) => ({
              SerialNumber: item.SerialNumber,
              from_time_stamp: ts.from_time_stamp,
              last_time_stamp: ts.last_time_stamp,
              status: ts.status,
            }))
          ) || [];
        setData(modifiedData);
        setTotalCount(modifiedData?.length || 0);
      }
    } else {
      setData([]);
      setTotalCount(0);
      toast(result?.message);
    }
  };
  const onGridReady = useCallback((params) => {
    if (gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
  });

  let check = true;
  useEffect(() => {
    if (check) {
      getData();
    }
    check = false;
  }, []);

  const handleDeleteClick = async (data) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this time frame details?"
    );
    if (!confirmDelete) {
      return;
    }
    if (gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
    const { SerialNumber, from_time_stamp, last_time_stamp } = data;
    var BASE_URL =
      PIPELINE_SERVER_URL + `/remove-timeframe/${SerialNumber}/timestamps`;

    try {
      const res = await fetch(BASE_URL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          time_stamps: [
            {
              from_time_stamp: from_time_stamp,
              last_time_stamp: last_time_stamp,
            },
          ],
        }),
      });

      const data = await res.json();
      if (res.ok) {
        toast.success(data?.message);
        setTimeout(() => {
          getData();
        }, 1000);
      } else {
        toast.error(data?.message);
        setTimeout(() => {
          getData();
        }, 1000);
      }
      if (gridRef.current) {
        gridRef.current.api.hideOverlay();
      }
    } catch (error) {
      console.error("Error during DELETE request:", error);
    }
  };

  const handleUpdateClick = async (data) => {
    if (gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
    const { SerialNumber, from_time_stamp, last_time_stamp } = data;
    var BASE_URL = PIPELINE_SERVER_URL + `/update-timestamp-status`;

    try {
      const res = await fetch(BASE_URL, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serialNumber: SerialNumber,
          from_time_stamp: from_time_stamp,
          last_time_stamp: last_time_stamp,
          status: 0,
        }),
      });

      const data = await res.json();
      if (res.ok) {
        toast.success(data?.message);
        setTimeout(() => {
          getData();
        }, 1000);
      } else {
        toast.error(data?.message);
        setTimeout(() => {
          getData();
        }, 1000);
      }
      if (gridRef.current) {
        gridRef.current.api.hideOverlay();
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.error("Error during update request:", error);
    }
  };

  const getDeviveSno = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    let BASE_URL = SERVER_URL + `/api/admin/get-device-sno/${id}`;
    const res = await fetch(BASE_URL, {
      method: "GET",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
      },
    });
    const result = await res.json();
    if (res.ok) {
      setDeviceSno(result.data);
    } else {
      // throw new Error("Failed to fetch data");
    }
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getData(newPage);
  };
  const insertTimestamps = async () => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    if (!deviceSnoVal) {
      toast("Serial number/ band id is mandatory");
      return false;
    }
    if (!startDate || !endDate) {
      toast("Time fields are mandatory");
      return false;
    }
    // let BASE_URL = SERVER_URL + `/api/admin/timestamps/${id}`;
    let BASE_URL = PIPELINE_SERVER_URL + `/serialnumber-timeframe-add`;

    const res = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("logged_res")
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        SerialNumber: deviceSnoVal,
        time_stamps: [
          {
            from_time_stamp: moment(startDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
            last_time_stamp: moment(endDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
          },
        ],
      }),
    });
    const result = await res.json();
    if (res.ok) {
      handleResetClick();
      toast.success(result.message);
      setTimeout(() => {
        getData();
      }, 1500);
    } else {
      toast.error(result.message);
    }
  };
  const showToasts = (data) => {
    data.forEach((item) => {
      const message = `${item.SerialNumber} - ${item.message}`;
      toast(message);
    });
  };
  const insertDeviceTimestamps = async (data) => {
    const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    if (!data || data.length === 0) {
      toast.error("At least 1 record to insert is required");
      return;
    }

    // Formatting the data as required
    const formattedData = data
      ?.filter((stat) => stat.status != 0)
      ?.reduce((acc, item) => {
        const existingEntry = acc.find(
          (entry) => entry.SerialNumber === item.SerialNumber
        );

        if (existingEntry) {
          // Add new timestamps to the existing SerialNumber entry
          existingEntry.time_stamps.push({
            from_time_stamp: item.from_time_stamp,
            last_time_stamp: item.last_time_stamp,
          });
        } else {
          // Create a new entry for the SerialNumber
          acc.push({
            SerialNumber: item.SerialNumber,
            time_stamps: [
              {
                from_time_stamp: item.from_time_stamp,
                last_time_stamp: item.last_time_stamp,
              },
            ],
          });
        }

        return acc;
      }, []);
    console.log(formattedData);
    if (formattedData.length == 0) {
      toast("Can't insert. All the selected ones have status false!")
      return;
    }
    // let BASE_URL = SERVER_URL + `/api/admin/timestamps/${id}`;
    let BASE_URL = PIPELINE_SERVER_URL + `/add-device`;
    try {
      const res = await fetch(BASE_URL, {
        method: "POST",
        headers: {
          authorization: `Bearer ${JSON.parse(
            localStorage.getItem("logged_res")
          )}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedData),
      });
      const result = await res.json();
      if (res.ok) {
        handleResetClick();
        showToasts(result)
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    getDeviveSno();
  }, []);

  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);

  const serialNumberCellRenderer = (params) => {
    const data = params.data;
    return <span>{data.SerialNumber}</span>;
  };

  const columnDefs = [
    {
      headerName: "S.No",
      field: "sno",
      minWidth: 100,
      valueGetter: (params) => params.node.rowIndex + 1,
      headerCheckboxSelection: true,
      // checkboxSelection: true,
      checkboxSelection: (params) => {
        // Disable checkbox if status is 0
        return params.data.status !== 0;
      },
      showDisabledCheckboxes: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: "Band Id/Serial Number",
      field: "SerialNumber",
      minWidth: 200,
      // cellRenderer: serialNumberCellRenderer,
    },
    {
      headerName: "From Time Stamp",
      field: "from_time_stamp",
      minWidth: 200,
    },
    {
      headerName: "Last Time Stamp",
      field: "last_time_stamp",
      minWidth: 200,
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 100,
      cellRenderer: (params) => (
        <div style={{ textAlign: "center", padding: "12px" }}>
          {" "}
          {params.value == 1 ? (
            <div className="green-dot" title="1"></div>
          ) : params.value == 0 ? (
            <div className="red-dot" title="0"></div>
          ) : (
            <div className="orange-dot" title="-1"></div>
          )}
        </div>
      ),
    },
    {
      headerName: "Actions",
      cellRenderer: (params) => (
        <>
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => handleDeleteClick(params.data)}
            title="Delete Timestamp"
          >
            <i className="fa fa-trash" />
          </button>{" "}
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => handleUpdateClick(params.data)}
            title="Update Timestamp Status"
          >
            <i className="fa fa-edit" />
          </button>{" "}
          <button
            className="btn btn-outline-info btn-sm"
            onClick={() =>
              insertDeviceTimestamps([
                {
                  SerialNumber: params.data.SerialNumber,
                  from_time_stamp: params.data.from_time_stamp,
                  last_time_stamp: params.data.last_time_stamp,
                  status: params.data.status,
                },
              ])
            }
            title="Add timestamp to device file"
            disabled={params.data.status == 0 ? true : false}
          >
            <i className="fa fa-add" />
          </button>
        </>
      ),
      minWidth: 205,
    },
  ];
  // ==============================================[For selected Data]=========================================
  const [selectedData, setSelectedData] = useState([]);
  const [rmvBtn, setRmvBtn] = useState(false);
  //   console.log(selectedData);
  const storedSelected = selectedData.map((sel) => {
    return {
      SerialNumber: sel.data.SerialNumber,
      from_time_stamp: sel.data.from_time_stamp,
      last_time_stamp: sel.data.last_time_stamp,
      status: sel.data.status,
    };
  });
  console.log(storedSelected);

  const onSelectionChanged = useCallback((event) => {
    var rowCount = event.api.getSelectedNodes().length;
    if (rowCount > 1) {
      setRmvBtn(true);
    }
    if (rowCount < 2) {
      setRmvBtn(false);
    }
    // console.log(event.api.getSelectedNodes());
    setSelectedData(event.api.getSelectedNodes());
    // window.alert("selection changed, " + rowCount + " rows selected");
  }, []);
  const onFilterChanged = () => {
    // Adjust the selection to consider only visible nodes after filtering
    gridRef.current.api.forEachNodeAfterFilterAndSort((node) =>
      node.setSelected(false)
    );
  };

  const onSelectAllFiltered = () => {
    // Select only the filtered rows
    gridRef.current.api.forEachNodeAfterFilterAndSort((node) =>
      node.setSelected(true)
    );
  };
  // ==============================================[For selected data End]=========================================

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    // make every column sortable
    flex: 1,
    sortable: true,
    // make every column filter
    filter: true,
    // filterParams: {
    //   buttons: ["reset", "apply"],
    //   // debounceMs: 100,
    // },
    // enable floating filters by default
    floatingFilter: true,
    // make columns resizable
    resizable: true,
  }));

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleButtonClick = () => {
    if (gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    }
    getData();
  };
  function handleResetClick() {
    setDeviceSnoVal("");
    setStartDate(null);
    setEndDate(null);
    // gridRef.current.api.showLoadingOverlay();
    // getData();
  }
  return (
    <div className="content-wrapper">
      <div className="row mt-3">
        <div className="col-lg-9 col-md-8 grid-margin stretch-card">
          <div className="card">
            <div
              className="d-flex manageLinkTable justify-content-between align-items-center"
              style={{ background: "#e7e7e7" }}
            >
              <h4 className="table_title card-title mb-1">
                Status File{" "}
                <span>
                  <button
                    className="btn btn-sm bg-white btn-icon-text border"
                    onClick={handleButtonClick}
                  >
                    <i
                      className="typcn typcn-refresh"
                      style={{ height: "4px", width: "4px" }}
                    ></i>{" "}
                  </button>
                </span>
              </h4>
              <div className="manageTablesOptions">
                {totalItems && totalItems > 0 ? (
                  <div>
                    <select
                      className="dropdown setpageSelection show form-control"
                      name="pagination"
                      style={{
                        padding: ".5rem .75rem",
                        borderRadius: "4px",
                      }}
                      onChange={setPagination}
                      value={paginationRow}
                      // onChange={(event) => setPagination(event)}
                    >
                      <option value={10}>show 10 rows</option>
                      <option value={20}>show 20 rows</option>
                      <option value={25}>show 25 rows</option>
                      <option value={50}>show 50 rows</option>
                    </select>
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  {rmvBtn && (
                    <button
                      onClick={() => insertDeviceTimestamps(storedSelected)}
                      className="btn removeAllBtn btn-sm btn-info btn-icon-text"
                      title="Add selected timestamps to device file"
                    >
                      Add Selected to device file
                      <i
                        className="fa fa-plus btn-icon-append"
                        aria-hidden="true"
                      ></i>
                    </button>
                  )}
                </div>
                {/* <div>
                  <button
                    type="button"
                    className={`btn excelBtn btn-sm btn-info btn-icon-text ${
                      data?.length > 0 ? "" : "disabled"
                    }`}
                    onClick={onBtnExport}
                  >
                    CSV
                    <i className="typcn typcn-download btn-icon-append"></i>
                  </button>
                </div> */}
                <div>
                  <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item nav-profile dropdown">
                      <a
                        className="nav-link dropdown-toggle pl-0 pr-0"
                        href="#"
                        data-toggle="dropdown"
                        id="profileDropdown"
                      >
                        <button
                          type="button"
                          className="btn btn-sm bg-white"
                          style={{ border: "solid #2b80ff 1px" }}
                        >
                          <i
                            className="fa fa-folder"
                            aria-hidden="true"
                            style={{
                              height: "8px",
                              width: "8px",
                              paddingRight: "16px",
                              color: "#2b80ff",
                            }}
                          ></i>
                        </button>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right navbar-dropdown"
                        aria-labelledby="profileDropdown"
                      >
                        <NavLink
                          to="/store-status-timestamps"
                          className="dropdown-item"
                        >
                          <i
                            className="fa fa-file-code text-dark"
                            aria-hidden="true"
                          ></i>{" "}
                          Status File data
                        </NavLink>
                        <NavLink
                          to="/store-device-timestamps"
                          className="dropdown-item"
                        >
                          <i
                            className="fa fa-laptop-code text-dark"
                            aria-hidden="true"
                          ></i>{" "}
                          Device File Data
                        </NavLink>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body" style={{ background: "#ffffff" }}>
              {/* On div wrapping Grid a) specify theme CSS className className and b) sets Grid size */}
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: 700 }}
              >
                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={data}
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  // paginationPageSize={paginationRow}
                  suppressExcelExport={true}
                  sortingOrder={sortingOrder}
                  enableCellTextSelection={true} // For cell data selection
                  rowSelection={"multiple"} // For row selection
                  suppressRowClickSelection={true} // For row selection
                  onSelectionChanged={onSelectionChanged} // For Getting number of rows selected
                  onGridReady={onGridReady}
                  overlayLoadingTemplate={
                    '<span className="ag-overlay-loading-center">Please wait while your records are loading...</span>'
                  }
                  rowHeight={50}
                  suppressPaginationPanel={true}
                  onFilterChanged={onFilterChanged}
                  // pagination={true}
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                />
              </div>
              {totalItems && totalItems > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <div
                        id="order-listing_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="order-listing_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing {startItem} to {endItem} of {totalItems}{" "}
                              entries{" "}
                              {/* Replace "10" with the number of items per page */}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="order-listing_paginate"
                            >
                              <ul className="pagination">
                                {/* Render the "Previous" button */}
                                <li
                                  className={`paginate_button page-item ${
                                    currentPage === 1 ? "disabled" : ""
                                  }`}
                                  id="order-listing_previous"
                                  onClick={() =>
                                    handlePageChange(currentPage - 1)
                                  }
                                >
                                  <a
                                    href="#"
                                    aria-controls="order-listing"
                                    data-dt-idx="0"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    <i
                                      className="fa fa-chevron-left"
                                      style={{
                                        height: "4px",
                                        width: "4px",
                                      }}
                                    ></i>
                                  </a>
                                </li>

                                {/* Render the current page number */}
                                <li className="paginate_button page-item active">
                                  <a
                                    href="#"
                                    aria-controls="order-listing"
                                    data-dt-idx="1"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    {currentPage}
                                  </a>
                                </li>

                                {/* Render the "Next" button */}
                                <li
                                  className={`paginate_button page-item ${
                                    totalItems === 0 ||
                                    currentPage * 10 >= totalItems
                                      ? "disabled"
                                      : ""
                                  }`}
                                  id="order-listing_next"
                                  onClick={() =>
                                    handlePageChange(currentPage + 1)
                                  }
                                >
                                  <a
                                    href="#"
                                    aria-controls="order-listing"
                                    data-dt-idx="2"
                                    tabIndex="0"
                                    className="page-link"
                                  >
                                    <i
                                      className="fa fa-chevron-right"
                                      style={{ height: "4px", width: "4px" }}
                                    ></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <div
                        id="order-listing_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            <div
                              className="dataTables_info"
                              id="order-listing_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing{" "}
                              {totalCount ? totalCount - (totalCount - 1) : 0}{" "}
                              to {totalCount ? totalCount : 0} of{" "}
                              {totalCount ? totalCount : 0} entries{" "}
                              {/* Replace "10" with the number of items per page */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 grid-margin stretch-card">
          <div className="card">
            <div
              className="card-body"
              style={{ background: "#ffffff", padding: "0.175rem 1.875rem" }}
            >
              <form
                className="forms-sample"
                autoComplete="off"
                // onSubmit={getData}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex manageLinkTable justify-content-between align-items-center mb-3">
                      <h5 className="mb-1 mt-4">
                        Add Time Stamps For Status File
                      </h5>
                    </div>
                    <div className="form-group">
                      <label htmlFor="datePicker">Serial Number/Band Id</label>
                      <select
                        className="dropdown show form-control"
                        name="pagination"
                        style={{
                          padding: ".5rem .75rem",
                          borderRadius: "4px",
                        }}
                        onChange={(e) => setDeviceSnoVal(e.target.value)}
                        required
                        value={deviceSnoVal}
                      >
                        <option value="">Select</option>
                        {deviceSno?.map((option, index) => {
                          return (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="startTimePicker">From</label>
                        <DateTimePicker
                          label={"Select in 24hr Format"}
                          className="form-control"
                          value={startDate}
                          onChange={handleStartDateChange}
                          textField={(params) => <TextField {...params} />}
                          format="YYYY-MM-DD HH:mm:ss"
                          views={[
                            "year",
                            "month",
                            "day",
                            "hours",
                            "minutes",
                            "seconds",
                          ]}
                          timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                          maxDate={moment()}
                          ampm={false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="endTimePicker">To</label>
                        <DateTimePicker
                          label={"Select in 24hr Format"}
                          className="form-control"
                          value={endDate}
                          onChange={handleEndDateChange}
                          textField={(params) => <TextField {...params} />}
                          format="YYYY-MM-DD HH:mm:ss"
                          views={[
                            "year",
                            "month",
                            "day",
                            "hours",
                            "minutes",
                            "seconds",
                          ]}
                          timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                          ampm={false}
                          maxDate={moment(startDate)}
                          // maxDate={moment(startDate).toDate()}
                        />
                      </div>
                    </div>
                  </LocalizationProvider>
                  <div className="form-group text-center">
                    <button
                      // type="submit"
                      type="button"
                      className="btn btn-primary px-5 py-3"
                      id="loginBtn"
                      onClick={insertTimestamps}
                    >
                      Add
                    </button>{" "}
                    <button
                      // type="submit"
                      type="button"
                      className="btn btn-dark mr-2 px-5 py-3"
                      id="loginBtn"
                      onClick={handleResetClick}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreStatusTimeStamps;
