import React, { useState, useRef, useEffect, useMemo } from "react";
import DataTable from "./DataTables/DataTable";
import { NavLink, Link } from "react-router-dom";
import toast from "react-hot-toast";
function Dashboard() {
  // ========================================[For Api and Data set Start]===================================================
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // =========================[For Sample Data Stored Combining trn_main and trn_log Start]==============================
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const getData = async () => {
    // const id = localStorage.getItem("adminId")?.replace(/"/g, "");
    // let BASE_URL = SERVER_URL + `/api/admin/get-trn-data/${id}`;
    // // Check if cached data exists
    // const cachedData = localStorage.getItem("cachedData");
    // if (cachedData) {
    //   setData(JSON.parse(cachedData));
    //   // return;
    // }
    // const res = await fetch(BASE_URL, {
    //   method: "GET",
    //   headers: {
    //     authorization: `Bearer ${JSON.parse(
    //       localStorage.getItem("logged_res")
    //     )}`,
    //     "Content-Type": "application/json",
    //   },
    // });
    // const result = await res.json();
    // const updatedData = result.data.map((row, index) => {
    //   return {
    //     ...row,
    //     index: (currentPage - 1) * Object.keys(result).length + index + 1,
    //   };
    // });
    // setData(updatedData);
    // // Cache the data
    // localStorage.setItem("cachedData", JSON.stringify(updatedData));
  };

  useEffect(() => {
    getData();
  }, [setData]);
  // }, []);

  // ========================================[For Filter Pending Start]===================================================
  const [pendingFilter, setPendingFilter] = useState([]);
  function pendingFilterFun() {
    data.map(() => {
      const datas = data.filter(
        (d) =>
          (d.status === "Pending" || d.status === "") && d.process_flag === 1
        // console.log(blog.category === activeCategory)
      );
      setPendingFilter(datas);
    });
  }
  useEffect(() => {
    pendingFilterFun();
  }, [data]);
  // ========================================[For Filter Success Start]===================================================
  const [successFilter, setSuccessFilter] = useState([]);
  function successFilterFun() {
    data.map(() => {
      const datas = data.filter(
        (d) => d.status === "Success"
        // console.log(blog.category === activeCategory)
      );

      setSuccessFilter(datas);
    });
  }
  useEffect(() => {
    successFilterFun();
  }, [data]);
  // ========================================[For Filter Error Start]===================================================
  const [errorFilter, setErrorFilter] = useState([]);
  function errorFilterFun() {
    data.map(() => {
      const datas = data.filter(
        (d) => d.status === "Error"
        // console.log(blog.category === activeCategory)
      );

      setErrorFilter(datas);
    });
  }
  useEffect(() => {
    errorFilterFun();
  }, [data]);
  // ========================================[For Filter Error End]===================================================
  // ========================================[For Filter In Progress Start]===================================================
  const [inProgressFilter, setInProgressFilter] = useState([]);
  function inProgressFilterFun() {
    data.map(() => {
      const datas = data.filter(
        (d) =>
          (d.status === "Pending" || d.status === "") && d.process_flag === 0
        // console.log(blog.category === activeCategory)
      );

      setInProgressFilter(datas);
    });
  }
  useEffect(() => {
    inProgressFilterFun();
  }, [data]);
  // ========================================[For Filter In Progress End]===================================================
  const [refresh, setRefresh] = useState(false);

  const handleButtonClick = async () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, 1000);
    await getData();
    pendingFilterFun();
    successFilterFun();
    inProgressFilterFun();
    errorFilterFun();
  };
  const buttonClick = () => {
    toast.success("Logged in successfully!", {
      duration: 5000,
      position: "top-center",
    });
  };
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col-sm-6">
          <h3 className="mb-1 font-weight-bold">Admin Dashboard</h3>
        </div>
        <div className="col-sm-6">
          <div
            className="d-flex align-items-center justify-content-md-end"
            style={{ justifyContent: "end" }}
          >
            <div className="mb-3 mb-xl-0 pr-1">
              <span>
                <button
                  className="btn btn-sm bg-white btn-icon-text"
                  onClick={handleButtonClick}
                >
                  Refresh
                  <i
                    className="typcn typcn-refresh"
                    style={{ height: "4px", width: "4px" }}
                  ></i>{" "}
                </button>
              </span>
              {/* <div className="dropdown">
                <button
                  className="btn bg-white btn-sm dropdown-toggle btn-icon-text border mr-2"
                  type="button"
                  id="dropdownMenu3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="typcn typcn-calendar-outline mr-2"></i>Last 7
                  days
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuSizeButton3"
                  data-x-placement="top-start"
                >
                  <h6 className="dropdown-header">Last 14 days</h6>
                  <a className="dropdown-item" href="#">
                    Last 21 days
                  </a>
                  <a className="dropdown-item" href="#">
                    Last 28 days
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row  mt-3">
        {/* <div className="col-xl-12 d-flex grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex flex-wrap justify-content-between">
                <h4 className="card-title mb-3">Admin Dashboard</h4>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="text-secondary mb-1 font-weight-bold">
                        13,221
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <DataTable refreshVal={refresh} />

            {/* <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-1">Bordered table</h4>
                <button
                  type="button"
                  className="btn btn-sm btn-info btn-icon-text"
                >
                  Excel
                  <i className="typcn typcn-download btn-icon-append"></i>
                </button>
              </div>
              <div className="table-responsive pt-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First name</th>
                      <th>Progress</th>
                      <th>Amount</th>
                      <th>Deadline</th>
                      <th>Action</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Herman Beck</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$ 77.99</td>
                      <td>May 15, 2015</td>
                      <td>
                        <label className="badge badge-success">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Messsy Adam</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$245.30</td>
                      <td>July 1, 2015</td>
                      <td>
                        <label className="badge badge-danger">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>John Richards</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            aria-valuenow="90"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$138.00</td>
                      <td>Apr 12, 2015</td>
                      <td>
                        <label className="badge badge-warning">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Peter Meggik</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$ 77.99</td>
                      <td>May 15, 2015</td>
                      <td>
                        <label className="badge badge-success">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Edward</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            aria-valuenow="35"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$ 160.25</td>
                      <td>May 03, 2015</td>
                      <td>
                        <label className="badge badge-success">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>John Doe</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            aria-valuenow="65"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$ 123.21</td>
                      <td>April 05, 2015</td>
                      <td>
                        <label className="badge badge-info">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Henry Tom</td>
                      <td>
                        <div className="progress">
                          <div
                            className="progress-bar bg-warning"
                            role="progressbar"
                            aria-valuenow="20"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </td>
                      <td>$ 150.00</td>
                      <td>June 16, 2015</td>
                      <td>
                        <label className="badge badge-success">Completed</label>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary btn-icon-text mr-1"
                        >
                          Edit
                          <i className="typcn typcn-edit  btn-icon-append"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-icon-text"
                        >
                          Delete
                          <i
                            className="fa fa-trash btn-icon-append"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
