import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logoSvg from "../../../logo.svg";
function Sidebar() {
  const [isStatus, setIsStatus] = useState(false);
  const location = useLocation();
  function checkStatus() {
    if (navigator.onLine) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }

  useEffect(() => {
    checkStatus();
  }, []);

  setInterval(() => {
    checkStatus();
  }, 1000);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item">
          <div className="d-flex sidebar-profile">
            <div className="sidebar-profile-image">
              <img src={logoSvg} alt="" />
              <span
                className="sidebar-status-indicator"
                style={{ background: `${isStatus ? "#17c964" : "#FF0000"}` }}
              ></span>
            </div>
            <div className="sidebar-profile-name">
              <p className="sidebar-name">Admin Dashboard</p>
              <p className="sidebar-designation">Welcome</p>
            </div>
          </div>
          {/* <p className="sidebar-menu-title">Dash menu</p> */}
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/dashboard" ? "active" : ""
          }`}
        >
          <NavLink to="/dashboard" className="nav-link">
            <i className="fa fa-desktop menu-icon"></i>
            <span className="menu-title">
              Dashboard
              {/* <span className="badge badge-primary ml-3">New</span> */}
            </span>
          </NavLink>
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/raw-data" ? "active" : ""
          }`}
        >
          <NavLink to="/raw-data" className="nav-link">
            <i className="fa fa-bar-chart menu-icon"></i>
            <span className="menu-title">Raw Data</span>
          </NavLink>
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/dog-profile" ? "active" : ""
          }`}
        >
          <NavLink to="/dog-profile" className="nav-link">
            <i className="fa fa-dog menu-icon"></i>
            <span className="menu-title">Dog Profile</span>
          </NavLink>
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/device" ? "active" : ""
          }`}
        >
          <NavLink to="/device" className="nav-link">
            <i className="fa fa-microchip menu-icon"></i>
            <span className="menu-title">Device</span>
          </NavLink>
        </li>
        <li
          className={`nav-item ${
            location.pathname === "/store-status-timestamps" ? "active" : ""
          }`}
        >
          <NavLink to="/store-status-timestamps" className="nav-link">
            <i className="fa fa-history menu-icon"></i>
            <span className="menu-title">Status Time Stamps</span>
          </NavLink>
        </li>
        {/* <li
          className={`nav-item ${
            location.pathname === "/successstate" ||
            location.pathname === "/errorstate" ||
            location.pathname === "/inprogressstate" ||
            location.pathname === "/sampletable" ||
            location.pathname === "/pendingstate"
              ? "active"
              : ""
          }`}
        >
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#process"
            aria-expanded="false"
            aria-controls="process"
          >
            <i className="fa fa-tasks menu-icon"></i>
            <span className="menu-title">Process State</span>
            <i className="typcn typcn-chevron-right menu-arrow"></i>
          </a>
          <div className="collapse" id="process">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <NavLink to="/successstate" className="nav-link">
                  Success
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/inprogressstate" className="nav-link">
                  In Progress
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/pendingstate" className="nav-link">
                  Pending
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/errorstate" className="nav-link">
                  Errors
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/sampletable" className="nav-link">
                  Sample Table
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}

        {/* <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#ui-basic"
            aria-expanded="false"
            aria-controls="ui-basic"
          >
            <i className="typcn typcn-briefcase menu-icon"></i>
            <span className="menu-title">Side Menu</span>
            <i className="typcn typcn-chevron-right menu-arrow"></i>
          </a>
          <div className="collapse" id="ui-basic">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Chil Menu 1
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Chil Menu 2
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Chil Menu 3
                </Link>
              </li>
            </ul>
          </div>
        </li> */}

        {/* <li className="nav-item">
          <Link to="/dashboard" className="nav-link">
            <i className="typcn typcn-document-text menu-icon"></i>
            <span className="menu-title">Side Menu</span>
          </Link>
        </li> */}
      </ul>
    </nav>
  );
}

export default Sidebar;
