import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-center text-sm-left d-block d-sm-inline-block">
          ©{new Date().getFullYear()}{" "}
          <a href="#" target="_blank">
            Pawzmo
          </a>{" "}
          . All Rights Reserved.
        </span>
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          Developed By{" "}
          <a href="https://bitbyteindia.com/" target="_blank">
            Bitbyteindia.com{" "}
          </a>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
